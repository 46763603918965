<template>
    <div id="home-body-content-wrapper" class="p-grid">
        <div
            v-if="topFeaturedArticle"
            id="feature-tile-article"
            class="p-col-12"
        >
            <a :href="topFeaturedArticle.link">
                <div class="feature-tile-img">
                    <img :src="getIdeaImage(topFeaturedArticle)" />
                </div>

                <div class="feature-tile-meta p-col-12">
                    <div class="feature-tile-meta-wrapper">
                        <div class="feature-tile-meta-head">
                            <span class="feature-tile-meta-tag">{{
                                getIdeaCategory(topFeaturedArticle)
                            }}</span>
                            <span class="feature-tile-meta-date">{{
                                formattedPublishedDate(topFeaturedArticle)
                            }}</span>
                        </div>

                        <div class="feature-tile-meta-title">
                            <span title="article title">{{
                                topFeaturedArticle.title?.rendered
                            }}</span>
                        </div>

                        <div class="feature-tile-meta-author">
                            Written by
                            <span>{{ topFeaturedArticle.article_author }}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>

        <div
            v-for="idea in featuredArticles"
            :key="idea.id"
            class="t2-tile-article p-col-12 p-md-6"
        >
            <a :href="idea.link">
                <div class="t2-tile-img">
                    <img :src="getIdeaImage(idea)" />
                </div>

                <div class="t2-tile-meta">
                    <div class="t2-tile-meta-wrapper">
                        <div class="t2-tile-meta-head">
                            <span class="t2-tile-meta-tag">{{
                                getIdeaCategory(idea)
                            }}</span>
                            <span class="t2-tile-meta-date">
                                {{ formattedPublishedDate(idea) }}
                            </span>
                        </div>
                        <div class="t2-tile-meta-title">
                            <span title="article title">{{
                                idea.title?.rendered
                            }}</span>
                        </div>

                        <div class="t2-tile-meta-author">
                            Written by
                            <span>{{ idea.article_author }}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { getLatestIdeas } from "../fetchFunctions";

export default {
    data() {
        return {
            topFeaturedArticle: undefined,
            featuredArticles: [],
        };
    },
    async created() {
        const [topFeatured, featured] = await Promise.all([
            getLatestIdeas({
                count: 1,
                embed: true,
                // only get posts w/ category "Top Featured"
                params: { categories: "16" },
            }),
            getLatestIdeas({
                count: 4,
                embed: true,
                // only get posts w/ category "Featured"
                params: {
                    categories: "15",
                    categories_exclude: "16",
                },
            }),
        ]);
        if (topFeatured.length) this.topFeaturedArticle = topFeatured[0];
        this.featuredArticles = featured;
    },
    methods: {
        // returns the first category that is not "featured"
        getIdeaCategory(idea) {
            return idea?.category_names?.find(
                x => x !== "Featured" && x !== "Top Feature"
            );
        },
        getIdeaImage(idea) {
            const featMedia = idea?._embedded?.["wp:featuredmedia"];
            return featMedia?.length && featMedia[0].source_url;
        },
        formattedPublishedDate(idea) {
            return new Date(idea && `${idea.date}Z`).toLocaleDateString(
                "default",
                {
                    month: "long",
                    day: "numeric",
                }
            );
        },
    },
};
</script>

<style scoped>
#feature-tile-article {
    position: relative;
}

.t2-tile-article {
    display: flex;
    flex-direction: column;
}

#feature-tile-article a,
.t2-tile-article a {
    text-decoration: none;
}

.feature-tile-meta,
.t2-tile-meta {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.feature-tile-meta {
    padding: 1.5rem 3rem;
}

.feature-tile-meta-title span,
.t2-tile-meta-title span {
    font-size: 1.75rem;
    font-family: "Roboto Slab";
    line-height: 2rem;
}

.t2-tile-meta-title span {
    font-size: 1.5rem;
}

.feature-tile-meta-tag,
.t2-tile-meta-tag {
    font-weight: bold;
    color: #ffc000;
}

.feature-tile-meta-date,
.t2-tile-meta-date,
.t2-tile-meta-author,
.feature-tile-meta-author {
    opacity: 0.5;
}

.t2-tile-meta-author,
.feature-tile-meta-author {
    margin-top: 0.5rem;
}

.feature-tile-meta-head span,
.t2-tile-meta-head span {
    margin-right: 0.5rem;
}

.feature-tile-meta,
.t2-tile-meta {
    background: #0557e7;
    color: white;
}

.feature-tile-img img,
.t2-tile-img img {
    display: block;
}
</style>
