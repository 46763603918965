/**
 *
 * Plugin for using trTracking, bound to Vue.$trTracking
 *
 * Can set default values for "apiUrl" "defaultGuid", "defaultTypetag" and "defaultOption" in plugin options during init,
 * which will set those to be the defaulted values for apiUrl, guid, typetag and option respectively for any events
 * fired. Can also be set programatically at any point through their respective variables
 *
 * NOTE: apiUrl will always default to "/extensions/adtrack.php" if not set
 *
 * Can also set custom actions in plugin options during init under "customActions", whose key should be the name-value of the
 * action and can additionally include a param "requirements" that should contain an array of parameter names required for
 * that actions event to fire correctly, and so that action will fail if the listed values are not present or valid.
 *
 * It is NOT necessary to declare a custom action to use a currently undefined action, but it is useful for additional
 * debugging purposes and to cleanly define requirements for those actions. Also note that the requirements listed are
 * simply those that MUST be present, and that any values submitted beyond that will still be included in the event call
 *
 *
 * Ex of potential options:
 * const options = {
 *      defaultGuid: ...,
 *      customActions: {
 *          myNewActionName: {
 *              requirements: ["customProperty1", "customProperty2", ...]
 *          }
 *      }
 * }
 *
 * Vue.use(trTracking, options)
 *
 * @author Jesse Bernz - Tightrope Interactive
 * @version 1.0.0
 */

const install = function(Vue, options = {}) {
    class trTracking {
        constructor(app, options) {
            // Required params for all events
            this._defaultRequirements = ["action", "evt_time", "option"];

            this._apiUrl = options.apiUrl || "/extensions/adtrack.php";

            const { typeTag, guid } = app.$store.state.attribution || {};
            this._defaultGuid = guid || options.defaultGuid;
            this._defaultTypetag = typeTag || options.defaultTypetag;
            this._defaultOption = options.defaultOption || null;

            this._actions = {};

            // Add any custom actions here defined in options.customAction
            if (options.customActions) {
                for (const i in options.customActions) {
                    if (!this._actions[i]) {
                        this._actions[i] = {
                            requirements: options.customActions[i].requirements,
                        };
                    } else {
                        console.error(
                            `Error in TrTracking initialization - Custom Method '${i}' already exists`
                        );
                    }
                }
            }

            // Firing Queue
            this._firingQueue = null;
            this._firingInterval = !isNaN(options.customFiringInterval)
                ? options.customFiringInterval
                : 1000;
        }

        set defaultGuid(guid) {
            this._defaultGuid = guid;
        }

        set defaultTypetag(typetag) {
            this._defaultTypetag = typetag;
        }

        set defaultOption(option) {
            this._defaultOption = option;
        }

        set apiUrl(url) {
            this._apiUrl = url;
        }

        /**
         * Function to send currently queued events to api. Duration defaults to every 1000ms but can be set by using option `customFiringInterval`
         * @returns {Promise} A promise that will resolve true if successful, and false for all other scenarios
         */
        async sendEvents() {
            return new Promise(resolve => {
                setTimeout(async () => {
                    // If there are any valid records
                    if (this._firingQueue && this._firingQueue.length > 0) {
                        // Set fetch options

                        // Check for and combine impressions
                        const currentQueue = [...this._firingQueue];
                        this._firingQueue = null;
                        const newQueue = [];

                        for (const i in currentQueue) {
                            if (currentQueue[i].action == "impression") {
                                const index = newQueue.findIndex(
                                    el =>
                                        currentQueue[i].feed == el.feed &&
                                        currentQueue[i].option == el.option
                                );
                                if (
                                    index > -1 &&
                                    newQueue[index].ads &&
                                    currentQueue[i].ads
                                ) {
                                    newQueue[index].ads =
                                        parseInt(newQueue[index].ads) +
                                        parseInt(currentQueue[i].ads);
                                } else newQueue.push(currentQueue[i]);
                            } else newQueue.push(currentQueue[i]);
                        }

                        const options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                records: newQueue,
                            }),
                        };

                        try {
                            const req = await fetch(this._apiUrl, options);
                            if (req.ok) {
                                resolve(true);
                            } else {
                                const text = await req.text();
                                console.error(
                                    "Error w/ tracking response from api\n",
                                    text
                                );
                                resolve(false);
                            }
                        } catch (e) {
                            console.error(
                                "Error w/ tracking response from api\n",
                                e
                            );
                            resolve(false);
                        }
                    }
                }, this._firingInterval);
            });
        }

        /**
         * Function to fire an array of events.
         * @param {Object} events Array of event params to fire.
         * * @param {Boolean} bypass whether to attempt event objects valaditiy check or not. Defaults to false
         * @returns {Boolean} Returns true if successful, else returns false
         */
        async fireMultipleEvents(events, bypass = false) {
            const getRequirements = reqList => {
                const defaultRequirements = this._defaultRequirements;

                let requirements = defaultRequirements;
                if (Array.isArray(reqList)) {
                    requirements = requirements.concat(reqList);

                    // Make sure there are no duplicates w/ default reqs
                    for (let i = 0; i < requirements.length; i++) {
                        for (let j = i + 1; j < requirements.length; j++) {
                            if (requirements[i] == requirements[j])
                                requirements.splice(j--, 1);
                        }
                    }
                }
                return requirements;
            };

            if (events && Array.isArray(events)) {
                const records = [];
                for (const i in events) {
                    const event = events[i];

                    const { action } = event;
                    if (!action) {
                        console.error("No action provided");
                        return false;
                    }

                    // Create body of request, populate with defaults and let params override them if set
                    const eventParams = {
                        evt_time: Date.now(),
                        guid: this._defaultGuid,
                        typetag: this._defaultTypetag,
                        option: this._defaultOption,
                        ...event,
                    };

                    // If this is a predeclared action type, check requirements. If none are set or bypass is true, skip this step, else check for required params and fail w/ error if there are any left
                    if (
                        this._actions &&
                        this._actions[action] &&
                        this._actions[action].requirements &&
                        !bypass
                    ) {
                        const requirements = getRequirements(
                            this._actions[action].requirements
                        );

                        const errors = [];
                        for (const i in requirements) {
                            if (!eventParams[requirements[i]]) {
                                errors.push(requirements[i]);
                            }
                        }
                        if (errors && errors.length > 0) {
                            console.error(
                                `Error firing event for action '${action}' - The Following requirements are not included: ${errors.join(
                                    ", "
                                )} \n Event: ${eventParams}`
                            );
                            return false;
                        }
                    }

                    // add to records to fire
                    records.push(eventParams);
                }

                if (!this._firingQueue) {
                    this._firingQueue = records;
                    this.sendEvents();
                } else {
                    this._firingQueue = this._firingQueue.concat(records);
                }
            }
            return false;
        }

        /**
         * Fire a single event
         * @param {Object} event object containing all event parameters
         * @param {Boolean} bypass whether to attempt event object valaditiy check or not. Defaults to false
         * @returns {Boolean} returns true if successful, false if it fails
         */
        fireEvent(event, bypass = false) {
            return this.fireMultipleEvents([event], bypass);
        }
    }

    try {
        let app = null;
        const version = parseInt(Vue.version);

        if (version == 3) {
            // Vue 3
            app = Vue.config.globalProperties;
        } else {
            // Vue 2
            app = Vue.prototype;
        }
        const params = new URLSearchParams(window.location.search);
        if (params.get("type"))
            localStorage.setItem("typeTag", params.get("type"));
        if (params.get("guid"))
            localStorage.setItem("guid", params.get("guid"));
        app.$store.registerModule("attribution", {
            state: {
                typeTag: localStorage.getItem("typeTag"),
                guid: localStorage.getItem("guid"),
            },
        });
        app.$trTracking = new trTracking(app, options);
    } catch (e) {
        console.error("Cannot correctly detect Vue version - ", e);
    }
};

export default { install };
