<template>
    <div id="auth-root">
        <div id="auth-wrapper">
            <div id="auth-header"></div>

            <div id="auth-body">
                <img
                    id="auth-logo"
                    src="/cooking/assets/images/freshy_recipes_logo.svg"
                    alt="cooking.freshy.com"
                />

                <div v-if="loaded" class="windowWrapper">
                    <div id="left-auth-promo">
                        <img
                            src="/cooking/assets/images/extauthPromo.jpg"
                            alt="Create an Account with Freshy Recipes"
                            data-v-b7bd5f58=""
                        />
                    </div>

                    <AuthWindow
                        :redirectOnLogin="goToLogin"
                        :redirectOnLogout="goToLogout"
                        :isResetPassword="isResetPassword"
                        :code="code"
                        @resetPasswordSuccess="onResetSuccess"
                    />
                </div>
            </div>

            <div id="auth-footer">
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import Footer from "../../components/footer/Footer.vue";
import AuthWindow from "../../components/auth/authWindow/AuthWindow.vue";
import { checkPasswordResetCode } from "../../auth/firebase";
import { mapActions } from "vuex";

export default {
    props: ["redirectOnLogin", "redirectOnLogout"],
    components: {
        AuthWindow,
        Footer,
    },
    data() {
        return {
            loaded: false,
            goToLogin: { name: "Home", params: { sync: true } },
            goToLogout: { name: "Home" },
            isResetPassword: null,
            code: null,
        };
    },
    async mounted() {
        try {
            if (this.redirectOnLogin)
                this.goToLogin = JSON.parse(this.redirectOnLogin);
        } catch {
            null;
        }

        try {
            if (this.redirectOnLogout)
                this.goToLogout = JSON.parse(this.redirectOnLogout);
        } catch {
            null;
        }

        const params = new URLSearchParams(window.location.search);

        if (params.get("mode") == "resetPassword" && params.get("oobCode")) {
            const valid = await checkPasswordResetCode(params.get("oobCode"));
            if (valid && valid.success) {
                // Valid Code
                this.isResetPassword = true;
                this.code = params.get("oobCode");
            } else if (valid && !valid.success) {
                // Invalid code
                null;
            }
        } else if (params.get("mode") === "logout") {
            await this.logout();
            this.$router.push(this.goToLogout);
        }

        if (params.get("continue")) {
            this.goToLogin = params.get("continue");
        }

        this.loaded = true;
    },
    methods: {
        ...mapActions(["logout"]),
        onResetSuccess() {
            this.$router.push(this.goToLogin);
        },
    },
};
</script>

<style scoped src="./auth.css"></style>
