<template>
    <div class="recipe-details-root" ref="recipeDetailsRoot">
        <!-- PAGE SECTION large- WILL NOT PRINT -->
        <div
            v-if="data && !isSmallVersion"
            class="recipe-details-wrapper p-d-block p-d-print-none"
        >
            <div class="recipe-details-top-default p-grid">
                <div class="recipe-details-img-wrapper p-col-12 p-md-4">
                    <img
                        @click="onImageClick()"
                        class="recipe-details-img p-d-print-inline-flex"
                        :src="data.imageAsset || data.image"
                    />
                </div>
                <div class="recipe-details-info p-col-12 p-md-8 ">
                    <div
                        v-if="data.canonical && canonicalHost"
                        class="recipe-source"
                    >
                        <a :href="data.canonical" target="_blank"
                            >{{ canonicalHost }}
                            <i class="pi pi-external-link"></i
                        ></a>
                    </div>
                    <div class="recipe-details-title-default">
                        {{ data.title }}
                    </div>
                    <div class="recipe-details-btn-wrapper">
                        <div class="recipe-details-save-btn-wrapper">
                            <recipe-save-btn
                                class="recipe-details-save-btn"
                                :recipe="data"
                                :bypassLogin="true"
                            ></recipe-save-btn>
                        </div>
                        <div
                            class="p-shadow-2 printBtn btn"
                            @click="onPrintClick"
                        >
                            <i class="pi pi-print"></i>
                            <span>Print Recipe</span>
                        </div>
                        <div class="nutritionBtn-wrapper">
                            <span
                                class="p-shadow-2 nutritionBtn btn"
                                @click="toggleNutrientWindow"
                                aria:haspopup="true"
                                aria-controls="overlay_panel"
                                ><i class="pi pi-list"></i>
                                <span>Nutrition Data</span>
                            </span>
                            <Overlay-Panel
                                id="nutrition-overlay-panel"
                                ref="nutrientWindow"
                                style="width: 450px"
                                :breakpoints="{ '960px': '75vw' }"
                            >
                                <div
                                    v-if="
                                        nutritionMetadata &&
                                            nutritionMetadata.length > 0
                                    "
                                    id="recipe-nutrition-overlay"
                                    class="recipe-details-meta p-grid"
                                >
                                    <div
                                        class="recipe-details-meta-label p-col-12"
                                    >
                                        Nutrition Data
                                        <span class="nutritional-note p-col-12"
                                            >Nutrition values are estimated
                                            based on title and/or
                                            ingredients</span
                                        >
                                    </div>
                                    <div
                                        v-for="(i, index) in nutritionMetadata"
                                        :key="index"
                                        class="recipe-details-meta-item-wrapper p-col-12 p-md-6"
                                    >
                                        <div class="recipe-details-meta-item">
                                            <span
                                                class="recipe-details-meta-item-label"
                                                >{{ i.label }}</span
                                            >
                                            <span
                                                class="recipe-details-meta-item-value"
                                                >{{ i.value }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </Overlay-Panel>
                        </div>
                    </div>

                    <div
                        v-if="metadata"
                        id="recipe-details"
                        class="recipe-details-meta p-grid"
                    >
                        <div class="recipe-details-meta-label p-col-12">
                            Details
                        </div>
                        <div
                            v-for="(meta, index) in metadata"
                            :key="index"
                            class="recipe-details-meta-item-wrapper p-col-12 p-md-6"
                        >
                            <div class="recipe-details-meta-item">
                                <span class="recipe-details-meta-item-label"
                                    >{{ meta.label }}
                                </span>
                                <span class="recipe-details-meta-item-value">{{
                                    meta.value
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="recipe-instructions-meta p-grid">
                        <div class="p-col-12 p-md-4">
                            <div
                                v-if="data.ingredients"
                                class="recipe-details-meta-label"
                            >
                                Ingredients
                            </div>
                            <ul>
                                <li
                                    v-for="(item, index) in data.ingredients"
                                    :key="index"
                                >
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                        <div class="p-col-12 p-md-8">
                            <div class="recipe-details-meta-label">
                                Preparation
                            </div>
                            <ol>
                                <li
                                    v-for="(step, index) in data.instructions"
                                    :key="index"
                                >
                                    {{ step }}
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div
                        v-if="nutritionMetadata && nutritionMetadata.length > 0"
                        id="recipe-nutrition"
                        class="recipe-details-meta p-grid p-d-md-none"
                    >
                        <div class="recipe-details-meta-label p-col-12">
                            Nutrition Data
                            <span class="nutritional-note p-col-12"
                                >Nutrition values are estimated based on title
                                and/or ingredients</span
                            >
                        </div>
                        <div
                            v-for="(i, index) in nutritionMetadata"
                            :key="index"
                            class="recipe-details-meta-item-wrapper p-col-12"
                        >
                            <div class="recipe-details-meta-item">
                                <span class="recipe-details-meta-item-label">{{
                                    i.label
                                }}</span>
                                <span class="recipe-details-meta-item-value">{{
                                    i.value
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="recipe-details-bottom"></div>
            <Dialog
                v-if="data && data.image"
                class="rd-large-image-dialog"
                :modal="true"
                :dismissableMask="true"
                :showHeader="false"
                contentClass="large-image-dialog-wrapper"
                :visible="showLargeImage"
                @update:visible="hideLargeImage"
            >
                <img class="rd-large-image" :src="data.image" />
            </Dialog>
        </div>
        <!-- END PAGE SECTION 1- WILL NOT PRINT -->
        <!-- PAGE SECTION small- WILL NOT PRINT -->
        <div v-else-if="data" class="recipe-details-wrapper p-d-print-none">
            <div class="recipe-details-top-default p-grid">
                <div class="recipe-details-info p-col-12 p-md-12">
                    <!-- top recipe -->

                    <div id="top-recipe" class="p-grid">
                        <div class="top-recipe-header p-col-12 p-md-9">
                            <div
                                v-if="canonicalHost && data.canonical"
                                class="recipe-source"
                            >
                                <a :href="data.canonical" target="_blank"
                                    >{{ canonicalHost }}
                                    <i class="pi pi-external-link"></i
                                ></a>
                            </div>
                            <div class="recipe-details-title">
                                {{ data.title }}
                            </div>
                            <div class="recipe-details-btn-wrapper">
                                <div
                                    class="p-shadow-2 printBtn btn"
                                    @click="onPrintClick"
                                >
                                    <i class="pi pi-print"></i>
                                    <span>Print Recipe</span>
                                </div>
                                <div class="nutritionBtn-wrapper">
                                    <span
                                        class="p-shadow-2 nutritionBtn btn"
                                        @click="toggleNutrientWindow"
                                        aria:haspopup="true"
                                        aria-controls="overlay_panel"
                                    >
                                        <span>Nutrition Data</span>
                                    </span>
                                    <Overlay-Panel
                                        id="nutrition-overlay-panel"
                                        ref="nutrientWindow"
                                        style="width: 450px"
                                        :breakpoints="{ '960px': '75vw' }"
                                    >
                                        <div
                                            v-if="
                                                nutritionMetadata &&
                                                    nutritionMetadata.length > 0
                                            "
                                            id="recipe-nutrition-overlay"
                                            class="recipe-details-meta p-grid"
                                        >
                                            <div
                                                class="recipe-details-meta-label p-col-12"
                                            >
                                                Nutrition Data
                                                <span
                                                    class="nutritional-note p-col-12"
                                                    >Nutrition values are
                                                    estimated based on title
                                                    and/or ingredients</span
                                                >
                                            </div>
                                            <div
                                                v-for="(i,
                                                index) in nutritionMetadata"
                                                :key="index"
                                                class="recipe-details-meta-item-wrapper p-col-12 p-md-6"
                                            >
                                                <div
                                                    class="recipe-details-meta-item"
                                                >
                                                    <span
                                                        class="recipe-details-meta-item-label"
                                                        >{{ i.label }}</span
                                                    >
                                                    <span
                                                        class="recipe-details-meta-item-value"
                                                        >{{ i.value }}</span
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </Overlay-Panel>
                                </div>
                            </div>

                            <div
                                v-if="metadata"
                                id="recipe-details"
                                class="recipe-details-meta p-grid"
                            >
                                <div class="recipe-details-meta-label p-col-12">
                                    Details
                                </div>
                                <div
                                    v-for="(meta, index) in metadata"
                                    :key="index"
                                    class="recipe-details-meta-item-wrapper p-col-12 p-md-6"
                                >
                                    <div class="recipe-details-meta-item">
                                        <span
                                            class="recipe-details-meta-item-label"
                                            >{{ meta.label }}
                                        </span>
                                        <span
                                            class="recipe-details-meta-item-value"
                                            >{{ meta.value }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div v-if="!noAds" class="adsense-wrapper">
                                <div class="adsense adsense46860">
                                    468 x 60 ad
                                </div>
                            </div>
                        </div>

                        <div class="recipe-details-img-wrapper p-col-12 p-md-3">
                            <img
                                @click="onImageClick()"
                                class="recipe-details-img p-d-print-inline-flex"
                                :src="data.image"
                            />
                        </div>
                    </div>
                    <!-- end top recipe -->

                    <div
                        id="recipeInstructions"
                        class="recipe-instructions-meta p-grid"
                    >
                        <div class="p-col-12 p-md-4">
                            <div
                                v-if="data.ingredients"
                                class="recipe-details-meta-label"
                            >
                                Ingredients
                            </div>
                            <ul>
                                <li
                                    v-for="(item, index) in data.ingredients"
                                    :key="index"
                                >
                                    {{ item }}
                                </li>
                            </ul>
                        </div>
                        <div class="p-col-12 p-md-8">
                            <div class="recipe-details-meta-label">
                                Preparation
                            </div>
                            <ol>
                                <li
                                    v-for="(step, index) in data.instructions"
                                    :key="index"
                                >
                                    {{ step }}
                                </li>
                            </ol>
                        </div>
                    </div>
                    <div
                        v-if="nutritionMetadata && nutritionMetadata.length > 0"
                        id="recipe-nutrition"
                        class="recipe-details-meta p-grid p-d-md-none"
                    >
                        <div class="recipe-details-meta-label p-col-12">
                            Nutrition Data
                            <span class="nutritional-note p-col-12"
                                >Nutrition values are estimated based on title
                                and/or ingredients</span
                            >
                        </div>
                        <div
                            v-for="(i, index) in nutritionMetadata"
                            :key="index"
                            class="recipe-details-meta-item-wrapper p-col-12"
                        >
                            <div class="recipe-details-meta-item">
                                <span class="recipe-details-meta-item-label">{{
                                    i.label
                                }}</span>
                                <span class="recipe-details-meta-item-value">{{
                                    i.value
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="recipe-details-bottom"></div>
            <Dialog
                v-if="data && data.image"
                class="rd-large-image-dialog"
                :modal="true"
                :dismissableMask="true"
                :showHeader="false"
                contentClass="large-image-dialog-wrapper"
                :visible="showLargeImage"
                @update:visible="hideLargeImage"
            >
                <img class="rd-large-image" :src="data.image" />
            </Dialog>
        </div>
        <!-- END PAGE SECTION 2- WILL NOT PRINT -->

        <!-- PRINT ONLY SECTION - WILL PRINT -->
        <div
            v-if="data"
            id="printRecipe"
            class="recipe-details-wrapper recipe-details-print p-d-none p-d-print-block"
        >
            <img
                id="printLogo"
                class="p-d-none p-d-print-block"
                src="/cooking/assets/images/freshy_recipes_logo.svg"
                alt="Cooking Freshy Recipes"
            />
            <div class="recipe-details-title">
                {{ data.title }}
            </div>

            <div class="recipe-instructions-meta p-grid">
                <div class="p-col-4">
                    <div
                        v-if="data.ingredients"
                        class="recipe-details-meta-label"
                    >
                        Ingredients
                    </div>
                    <ul>
                        <li
                            v-for="(item, index) in data.ingredients"
                            :key="index"
                        >
                            {{ item }}
                        </li>
                    </ul>
                </div>
                <div class="p-col-8">
                    <div class="recipe-details-meta-label">Preparation</div>
                    <ol>
                        <li
                            v-for="(step, index) in data.instructions"
                            :key="index"
                        >
                            {{ step }}
                        </li>
                    </ol>
                </div>
            </div>
        </div>

        <div
            id="print-details"
            class="recipe-details-info p-col-12 p-d-none p-d-print-block"
        >
            <div id="print-title" class="recipe-details-meta-label">
                {{ data.title }}
            </div>

            <div v-if="metadata" class="recipe-details-meta p-grid">
                <div class="recipe-details-meta-label p-col-12">
                    Details
                </div>
                <div
                    v-for="(meta, index) in metadata"
                    :key="index"
                    class="recipe-details-meta-item-wrapper p-col-4"
                >
                    <div class="recipe-details-meta-item">
                        <span class="recipe-details-meta-item-label"
                            >{{ meta.label }}
                        </span>
                        <span class="recipe-details-meta-item-value">{{
                            meta.value
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="recipe-details-bottom">
                <div
                    v-if="nutritionMetadata && nutritionMetadata.length > 0"
                    id="recipe-nutrition"
                    class="recipe-details-meta p-grid"
                >
                    <div class="recipe-details-meta-label p-col-12">
                        Nutrition Data
                        <span class="nutritional-note p-col-12"
                            >Nutrition values are estimated based on title
                            and/or ingredients</span
                        >
                    </div>
                    <div
                        v-for="(i, index) in nutritionMetadata"
                        :key="index"
                        class="recipe-details-meta-item-wrapper p-col-4"
                    >
                        <div class="recipe-details-meta-item">
                            <span class="recipe-details-meta-item-label">{{
                                i.label
                            }}</span>
                            <span class="recipe-details-meta-item-value">{{
                                i.value
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END PRINT ONLY SECTION - WILL PRINT -->
    </div>
</template>

<script>
import recipeMixin from "../componentMixins/recipeMixin";
import OverlayPanel from "primevue/overlaypanel";
import Dialog from "primevue/dialog";
import RecipeSaveBtn from "../recipeSaveBtn/RecipeSaveBtn";
import { getShortMonthName, getDateSuffix } from "../../utility";

export default {
    name: "recipe-details-overlay",
    mixins: [recipeMixin],
    props: [
        "data",
        "visible",
        "noAds",
        "removable",
        "smallVersion",
        "createdOn",
    ],
    components: {
        OverlayPanel,
        RecipeSaveBtn,
        Dialog,
    },
    data() {
        return {
            show: false,
            showLargeImage: false,
        };
    },
    mounted() {
        this.show = this.visible;
        window.scrollTo(0, 0);

        this.$nextTick(() => {
            const ga = document.querySelector("#ga_sticky_bottom");
            if (ga) ga.classList.add("p-d-print-none");
        });
    },
    watch: {
        visible() {
            this.show = this.visible;
        },
    },
    computed: {
        formattedCreatedOn() {
            if (!this.createdOn || isNaN(this.createdOn)) return null;

            const date = new Date(this.createdOn);
            const month = getShortMonthName(date.getMonth());
            const day = date.getDate();
            const year = date.getFullYear();

            if (month && day && year)
                return `${month} ${day}${getDateSuffix(day)}, ${year}`;
            return null;
        },
        canonicalHost() {
            try {
                if (this.data && this.data.canonical) {
                    const url = new URL(this.data.canonical);
                    if (url && url.host) return url.host;
                }
                return null;
            } catch {
                return null;
            }
        },
        isSmallVersion() {
            return this.smallVersion || null;
        },
        metadata() {
            const out = [];
            if (this.data) {
                const r = this.data;

                const addMeta = (label, value) => out.push({ label, value });

                // Order Matters

                // Adding Created Date First
                // if(this.formattedCreatedOn) addMeta("Added To Cookbook", this.formattedCreatedOn)

                if (r.total_time && this.formatTimeFromMinutes(r.total_time))
                    addMeta(
                        "Total Time",
                        this.formatTimeFromMinutes(r.total_time)
                    );
                if (r.yields) addMeta("Serving Size", r.yields);
                return out != [] ? out : null;
            }
            return null;
        },
        nutritionMetadata() {
            const out = [];
            const addMeta = (label, value, unit = null) =>
                out.push({ label, value, unit });

            let n = null;
            if (
                this.data &&
                this.data.nutrients &&
                Object.keys(this.data.nutrients).length > 0
            )
                n = this.data.nutrients;
            else if (
                this.data &&
                this.data.nutrient_details &&
                Object.keys(this.data.nutrient_details).length > 0
            )
                n = this.data.nutrient_details;

            for (const i in n) {
                addMeta(this.parseNutrientString(i), n[i]);
            }

            if (out && out.length > 0) return out;
            return null;
        },
    },
    methods: {
        onImageClick() {
            this.showLargeImage = true;
        },
        hideLargeImage(value) {
            if (!value) this.showLargeImage = false;
        },
        onShow() {
            const closeBtn = document.querySelector(".p-sidebar-close");
            if (closeBtn)
                closeBtn.onclick = () => {
                    this.show = false;
                };
        },
        onHide() {},
        toggleNutrientWindow(event) {
            this.$refs.nutrientWindow.toggle(event);
        },
        onPrintClick() {
            if (window) {
                window.print();
            }
        },
    },
};
</script>

<style src="./recipeDetails.css"></style>
