import { getTypeTag } from "@/utility";
// Standard Fetch Functions

const STANDARD_TIMEOUT = 5000;
const YAHOO_TIMEOUT = 2000;

const unescape = htmlStr => {
    htmlStr = htmlStr.replace(/&lt;/g, "<");
    htmlStr = htmlStr.replace(/&gt;/g, ">");
    htmlStr = htmlStr.replace(/&quot;/g, '"');
    htmlStr = htmlStr.replace(/&#39;/g, "'");
    htmlStr = htmlStr.replace(/&amp;/g, "&");
    return htmlStr;
};

const fetchWithAbort = async (
    url,
    timeout = STANDARD_TIMEOUT,
    abortMessage = null,
    options = null
) => {
    try {
        const controller = new AbortController();
        const id = setTimeout(() => {
            controller.abort();
            if (abortMessage) console.error(abortMessage);
        }, timeout);

        const opt = options ? { ...options } : {};
        opt.signal = controller.signal;
        const req = await fetch(url, opt);
        clearTimeout(id);
        return req;
    } catch (e) {
        console.error(`Error with fetch at ${url} - \n${e}`);
        return null;
    }
};

const getRecipeFilterImageUrls = async () => {
    const req = await fetch("/cooking/assets/resources/placeholderImages.json");
    if (req.ok) {
        const res = await req.json();
        if (res && res.urls) return res.urls;
    }
    return null;
};

async function getTopRecipe() {
    const url = "/api/v1/recipes/top-recipe";

    try {
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Timeout with Top Recipes"
        );
        if (req.ok) {
            const json = await req.json();
            if (
                json &&
                json.status &&
                json.results &&
                Object.keys(json.results) &&
                Object.keys(json.results).length > 0
            ) {
                const res = json.results[Object.keys(json.results)[0]];
                if (res && res.status) return res;
            }
        }
    } catch (e) {
        console.error(`Error with getting top - ${e}`);
    }

    return null;
}

async function getPopularRecipes() {
    const url = "/api/v1/recipes/favorites?count=10";
    const options = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    try {
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Timeout getting popular recipes",
            options
        );
        if (req.ok) {
            const res = await req.json();
            if (res && res.main) return res.main;
        }
    } catch (e) {
        console.error(`Error with getting popular - ${e}`);
    }

    return null;
}

async function getRecommendations(recipes) {
    const filterUrls = await getRecipeFilterImageUrls();

    const url = "/core/v2/recommend";
    const items = Object.entries(recipes)
        .filter(([, v]) => v.canonical || v.source)
        .map(([, v]) => ({
            id: v.source || v.canonical,
            domain_id: "*",
        }));
    const body = {
        items,
        group: parseInt(process.env.VUE_APP_INDEXER_GROUP),
        filters: {
            resultImage: {
                not: filterUrls,
            },
            recipedata_available: {
                term: true,
            },
        },
    };
    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    try {
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Error getting recommended recipes",
            options
        );
        if (req.ok) {
            const res = await req.json();
            if (res && res.main && res.main.records) {
                return res.main.records;
            }
        }
    } catch (e) {
        console.error(`Error with Getting recommendations - ${e}`);
    }
    return null;
}

async function recipeSearch(query, typeTag, queryOptions = {}, aiFilter) {
    const { perPage, pageNo } = queryOptions;

    const filterUrls = await getRecipeFilterImageUrls();
    const url = "/core/v2/search";
    const body = {
        q: query || "",
        facets: {
            recipedata: true, // required to have recipeData
            url: true,
            uuid: true,
        },
        filters: {
            resultImage: {
                not: filterUrls,
            },
            recipedata_available: {
                term: true,
            },
            recipe_quality: {
                term: "good",
            },
            ai_content_available: {
                not: {
                    term: true,
                },
            },
        },
    };

    if (aiFilter) {
        body.domain = [158];
    } else {
        body.group = parseInt(process.env.VUE_APP_INDEXER_GROUP);
    }

    if (perPage) body.perPage = perPage;
    if (pageNo) body.pageNo = pageNo;

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    try {
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Error getting search results",
            options
        );
        if (req.ok) {
            const res = await req.json();
            if (res && res.main) return res.main;
        }
    } catch (e) {
        console.error(`Error with getting search - ${e}`);
    }

    return null;
}

async function filterAIRecipes(tags) {
    const filterUrls = await getRecipeFilterImageUrls();
    const url = "/core/v2/search";
    const body = {
        q: "*",
        facets: {
            recipedata: true, // required to have recipeData
            url: true,
            uuid: true,
        },
        filters: {
            tags,
            resultImage: {
                not: filterUrls,
            },
            recipedata_available: {
                term: true,
            },
            recipe_quality: {
                term: "good",
            },
        },
        domain: [158],
    };

    body.domain = [158];
    body.perPage = 100;

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    try {
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Error getting search results",
            options
        );
        if (req.ok) {
            const res = await req.json();
            if (res && res.main) return res.main;
        }
    } catch (e) {
        console.error(`Error with getting search - ${e}`);
    }

    return null;
}

async function recipesAutocompleteSearch(term) {
    const filterUrls = await getRecipeFilterImageUrls();

    const body = {
        group: parseInt(process.env.VUE_APP_INDEXER_GROUP),
        q: term,
        perPage: 20,
        pageNo: 0,
        field: "resultEntity",
        out_fields: ["recipe_quality"],
        facets: {
            recipedata: true, // required to have recipeData
            url: true,
            uuid: true,
        },
        filters: {
            resultImage: { not: filterUrls },
            recipedata_available: {
                term: true,
            },
        },
    };

    const options = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
    };

    try {
        const req = await fetchWithAbort(
            "core/v2/complete",
            STANDARD_TIMEOUT,
            "Timeout getting recipe autocomplete results",
            options
        );
        if (req.ok) {
            const res = await req.json().catch(() => ({
                error: "Bad Response",
                query: term,
                results: [],
            }));

            if (
                res &&
                res.main &&
                res.main.records &&
                res.main.records.length > 0
            ) {
                // filter by recipe quality, take max 10 items
                res.main.records = res.main.records
                    .filter(i => i.recipe_quality === "good")
                    .slice(0, 10);
                res.main.records.map(i => (i.text = unescape(i.text)));
                res.main.records.map(
                    i => (i.resultEntity = unescape(i.resultEntity))
                );
                return res.main.records;
            }
        }
    } catch (e) {
        console.error(`Error with getting top - ${e}`);
    }

    return null;
}

async function getRecipesFromApi(value, type = "uuid") {
    try {
        if ((type == "uuid" || type == "url") && value) {
            if (!Array.isArray(value)) value = [value];

            const url = "/api/v1/recipes/get-recipes";
            const body = {
                items: value,
                item_type: type,
            };

            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            };

            // TBD - Write function to check for extension values and merge with body here...

            const req = await fetchWithAbort(
                url,
                STANDARD_TIMEOUT,
                "Timeout getting recipes by uuid from api",
                options
            );
            if (req.ok) {
                const res = req.json();
                return res;
            }
            return false;
        }
        return false;
    } catch (e) {
        console.error("Error getting recipes by UUID from api", e);
        return false;
    }
}

const CURRENT_HOST = `${new URL(window.location).origin}`;

async function getYahooAds(query, typeTag = null) {
    if (query) {
        const YAHOO_SEARCH_API_URL = "/api/get.php";
        const type = typeTag || `Y225_F1_207321_`;
        const url = `${YAHOO_SEARCH_API_URL}?serve_url=${CURRENT_HOST}${
            window.location.pathname
        }&q=${query}&ads=5&organic=0&type=${getTypeTag(type)}`;

        try {
            const req = await fetchWithAbort(
                url,
                YAHOO_TIMEOUT,
                "Error getting yahoo sponsored results"
            );
            if (req.ok) {
                const res = await req.json();
                return res;
            }
        } catch (e) {
            console.error("Error getting yahoo res", e);
        }
    }
    return null;
}

async function getYFeed() {
    try {
        const url = "/y-feeds/news/v1/nutrition?length=-1";
        const req = await fetchWithAbort(
            url,
            YAHOO_TIMEOUT,
            "Error getting nutrition y-feed"
        );
        if (req.ok) {
            const res = await req.json();
            if (res && res[0] && Object.keys(res[0]).length > 0) return res[0];
        }

        return null;
    } catch (e) {
        console.error("Error getting y-feed - ", e);
        return null;
    }
}

async function getAds(serviceList, options) {
    try {
        const body = {
            services: serviceList,
            options,
        };

        // TBD - Write function to check for extension values and merge with body here...

        const opt = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        };

        const req = await fetchWithAbort(
            "/ads",
            STANDARD_TIMEOUT,
            "Timeout on getting ads from api",
            opt
        );
        if (req.ok) {
            const res = req.json();
            return res;
        }
        return false;
    } catch (e) {
        console.error("Error getting ads from api", e);
        return false;
    }
}

async function getLatestIdeas(options = { count: 1, embed: false }) {
    try {
        let qs = {
            per_page: options.count,
        };
        if (options.embed) qs._embed = true;
        if (options.params)
            qs = {
                ...qs,
                ...options.params,
            };

        const url = `/ideas/wp-json/wp/v2/posts?${new URLSearchParams(qs)}`;
        const opt = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const req = await fetchWithAbort(
            url,
            STANDARD_TIMEOUT,
            "Timeout on getting latest ideas",
            opt
        );
        if (req.ok) {
            const res = req.json();
            return res;
        }
        return false;
    } catch (e) {
        console.error("Error getting latest WordPress from api", e);
        return false;
    }
}

async function getDefaultAdbladeAds() {
    const ads = await getAds(["adblade"]);
    if (ads && ads[0] && ads[0].id == "adblade") return ads[0];
    return null;
}

async function getDefaultAdnetAds(
    query = "Holiday Gifts",
    said = "Other",
    mark_url = "https://www.groupon.com/browse/san-francisco?category=food-and-drink&subcategory=groceries-and-markets"
) {
    const ads = await getAds(["adnet"], {
        adnet: {
            said,
            query,
            mark_url,
        },
    });
    if (ads && ads[0] && ads[0].id == "adnet") return ads[0];
    return null;
}

export {
    getTopRecipe,
    getPopularRecipes,
    getRecommendations,
    recipeSearch,
    recipesAutocompleteSearch,
    getRecipesFromApi,
    getRecipeFilterImageUrls,
    getYahooAds,
    getYFeed,
    getAds,
    getLatestIdeas,
    getDefaultAdbladeAds,
    getDefaultAdnetAds,
    CURRENT_HOST,
    filterAIRecipes,
};
