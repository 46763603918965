<template>
    <div id="recipe-root">
        <OverlayAuthWindow
            v-if="showLogin"
            :escapable="false"
            @login="hideDialog"
            @sendToExtension="hideDialog"
        ></OverlayAuthWindow>
        <div id="recipe-root-wrapper">
            <div id="recipe-header">
                <Header />
            </div>
            <div id="recipe-body">
                <recipe-details
                    v-if="currData && !smartRecipe"
                    :data="currData"
                    :noAds="false"
                    :visible="true"
                />
                <smart-recipe-details
                    v-if="currData && smartRecipe"
                    :data="currData"
                    :regularData="regularData"
                    :noAds="false"
                    :visible="true"
                />
                <recommendation-carousel
                    v-if="currData"
                    :key="currData"
                    :seedRecipes="[currData]"
                    :subheader="
                        `Delicious recommendations based on ${currData.title}`
                    "
                ></recommendation-carousel>
            </div>
            <div id="recipe-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";
import RecipeDetails from "../../components/recipeDetails/RecipeDetails.vue";
import SmartRecipeDetails from "../../components/smartRecipeDetails/RecipeDetails.vue";
import RecommendationCarousel from "../../components/recommendationCarousel/RecommendationCarousel";
import OverlayAuthWindow from "../../components/auth/authWindow/OverlayAuthWindow.vue";
import { getRecipesFromApi } from "../../fetchFunctions";
import userMixin from "../../store/mixins/user";
import extensionMixin from "../../components/componentMixins/extensionMixin";

import { BASE_TITLE } from "../../router";

export default {
    mixins: [userMixin, extensionMixin],
    components: {
        Header,
        Footer,
        RecipeDetails,
        SmartRecipeDetails,
        RecommendationCarousel,
        OverlayAuthWindow,
    },
    async created() {
        const { uuid } = this.$route.params;

        // Check if recipe exists here, if so, pass data on as param, else redirect to 404.
        if (uuid) {
            const data = await getRecipesFromApi(uuid);
            if (
                data &&
                data.results &&
                data.results[uuid] &&
                data.results[uuid].status
            ) {
                data.results[uuid].data.uuid = uuid;
                this.currData = data.results[uuid].data;
                this.regularData = data.results[uuid].data;

                if (data.results[uuid].data && data.results[uuid].data.title)
                    document.title = BASE_TITLE + data.results[uuid].data.title;
                else document.title = `${BASE_TITLE}Recipe`;
                if (
                    data.results &&
                    data.results[uuid].ai_recipe &&
                    data.results[uuid].ai_recipe !== null
                ) {
                    this.smartRecipe = data.results[uuid].ai_recipe;

                    this.currData = data.results[uuid].ai_recipe;
                }
            } else {
                this.$router.replace({ name: "NotFound" });
            }
        } else {
            this.$router.replace({ name: "NotFound" });
        }
    },
    data() {
        return {
            currData: null,
            showLogin: false,
            smartRecipe: false,
            regularData: null,
        };
    },
    mounted() {},
    methods: {
        hideDialog() {
            this.showLogin = false;
        },
    },
};
</script>

<style scoped src="./recipe.css"></style>
