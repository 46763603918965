<template>
    <div id="account-management-root">
        <div
            v-if="loggedIn && activeUser && ready"
            id="account-management-wrapper"
        >
            <div id="account-management-header">
                <Header />
            </div>
            <div id="account-management-body" class="outer-wrapper">
                <div id="account-management-body-wrapper" class="p-grid">
                    <div class="am-user-info-title-wrapper p-col-12">
                        <span class="am-user-info-title noUserSelect"
                            >Account Profile</span
                        >
                    </div>
                    <div id="am-user-info-wrapper" class="p-col-12 p-md-4">
                        <div
                            class="am-user-info-email-wrapper am-account-user-info-wrapper"
                        >
                            <div
                                class="am-user-info-email-top am-user-info-top"
                            >
                                <span class="am-user-info-subtitle noUserSelect"
                                    >Email Address</span
                                >
                                <!-- DO NOT SUPPORT CHANGING EMAIL FOR NOW <span class="am-user-info-cta"></span>-->
                            </div>
                            <div
                                class="am-user-info-email-bottom am-user-info-bottom"
                            >
                                <span
                                    class="am-user-info-item am-user-info-email"
                                    >{{ activeUser.email }}</span
                                >
                            </div>
                            <div
                                v-if="!activeUser.emailVerified"
                                class="am-user-info-email-unverified"
                            >
                                EMAIL UNVERIFIED -
                                <span
                                    class="am-user-info-email-unverified-cta"
                                    @click="resendVerificationEmail()"
                                    >Resend Verification Email</span
                                >
                            </div>
                        </div>
                        <div
                            class="am-user-info-password-wrapper am-account-user-info-wrapper"
                        >
                            <div
                                class="am-user-info-password-top am-user-info-top"
                            >
                                <span class="am-user-info-subtitle noUserSelect"
                                    >Password</span
                                >
                                <span
                                    v-if="showPasswordOptions"
                                    class="am-user-info-cta noUserSelect"
                                    @click="updatePassword()"
                                    >Change Password</span
                                >
                            </div>
                            <div
                                class="am-user-info-password-bottom am-user-info-bottom"
                            >
                                <span
                                    v-if="showPasswordOptions"
                                    class="am-user-info-item am-user-info-password"
                                    >**********</span
                                >
                                <span
                                    v-else
                                    class="am-user-info-item am-user-info-password"
                                    >Visit account provider to change
                                    password</span
                                >
                            </div>
                        </div>
                        <div
                            v-if="accountType"
                            class="am-user-info-account-type-wrapper am-account-user-info-wrapper"
                        >
                            <div
                                class="am-user-info-account-type-top am-user-info-top"
                            >
                                <span class="am-user-info-subtitle noUserSelect"
                                    >Linked Accounts</span
                                >
                            </div>
                            <div
                                class="am-user-info-account-type-bottom am-user-info-bottom"
                            >
                                <div
                                    class="am-user-info-item am-user-info-account-type"
                                >
                                    {{ accountType }}
                                </div>
                            </div>
                        </div>

                        <div
                            class="am-user-account-delete-wrapper am-member-info-type am-account-user-info-wrapper"
                        >
                            <div
                                class="am-user-account-delete-top am-user-info-top"
                            >
                                <span class="am-user-info-subtitle noUserSelect"
                                    >Delete My Account</span
                                >
                            </div>

                            <div
                                class="am-user-account-delete-bottom am-user-info-bottom"
                            >
                                <div
                                    class="am-user-account-delete-btn noUserSelect"
                                    @click="onDeleteAccountClick()"
                                >
                                    Delete Account
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="divider"
                        class="p-d-none p-d-md-inline-flex p-col-1"
                    >
                        <Divider layout="vertical" />
                    </div>
                    <div id="am-user-account-wrapper" class="p-col-12 p-md-7">
                        <div class="am-member-wrapper">
                            <div
                                class="am-account-user-info-wrapper am-member-info-wrapper am-user-account-info-wrapper p-grid"
                            >
                                <h3 class="p-md-12">
                                    Cooking Freshy Membership
                                </h3>
                                <div class="p-md-3">
                                    <div
                                        class="am-user-info-membership-top am-user-info-top"
                                    >
                                        <span
                                            class="am-member-info-title am-user-account-title am-user-info-subtitle noUserSelect"
                                            >Membership</span
                                        >
                                    </div>
                                    <div
                                        class="am-user-info-membership-bottom am-user-info-bottom"
                                    >
                                        <div
                                            class="am-member-info-type am-user-account-text"
                                        >
                                            {{ formattedAccountType }}
                                        </div>
                                    </div>
                                </div>

                                <div class="p-md-3">
                                    <div
                                        v-if="joinDate"
                                        class="am-member-info-join-date am-member-info-type"
                                    >
                                        <div
                                            class="am-user-info-password-top am-user-info-top"
                                        >
                                            <span
                                                class="am-user-info-subtitle noUserSelect"
                                                >Account Created</span
                                            ><!--v-if-->
                                        </div>

                                        <div
                                            class="am-user-info-password-bottom am-user-info-bottom"
                                        >
                                            <span v-html="joinDate"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id="premiumPromo"
                                class="am-member-cta-wrapper p-col-12"
                            >
                                <div
                                    class="am-user-info-membership-top am-user-info-top"
                                >
                                    <span
                                        v-if="activeUser.premium"
                                        class="am-member-cta noUserSelect"
                                        >Update Subscription Information</span
                                    >
                                    <span
                                        v-else
                                        class="am-member-cta noUserSelect p-col-12"
                                        ><h3>Get Premium Membership</h3></span
                                    >
                                </div>

                                <div id="promoBox" class="p-grid">
                                    <div class="p-md-4">
                                        <img
                                            src="/cooking/assets/images/premiumPromo.png"
                                        />
                                    </div>
                                    <div class="p-ml-1 p-md-7">
                                        <h4>Join now to get these features</h4>
                                        <ul>
                                            <li>
                                                Access to THOUSANDS of top rated
                                                recipes
                                            </li>
                                            <li>
                                                Save unlimited recipes to your
                                                personal recipebook
                                            </li>
                                            <li>
                                                View your saved recipes on any
                                                device
                                            </li>
                                        </ul>

                                        <div
                                            class="am-user-account-delete-bottom am-user-info-bottom"
                                        >
                                            <div
                                                class="p-button noUserSelect p-button-lg p-button-raised"
                                            >
                                                Join Freshy
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="account-management-footer">
                <Footer></Footer>
            </div>
            <Dialog
                id="am-update-password-dialog"
                :modal="true"
                :dismissableMask="true"
                :showHeader="false"
                contentClass="am-auth-dialog-wrapper"
                :visible="showUpdatePasswordDialog"
                @update:visible="hideupdatePasswordDialog"
            >
                <AuthWindow
                    :isUpdatePassword="true"
                    @passwordUpdated="onUpdatePassword"
                />
            </Dialog>
            <Dialog
                id="am-reauth-dialog"
                :modal="true"
                :dismissableMask="true"
                :showHeader="false"
                contentClass="am-auth-dialog-wrapper"
                :visible="showReauthWindow"
                @update:visible="hideDialog"
            >
                <AuthWindow
                    :isReauthentication="true"
                    @reauthenticated="onReauthenticated"
                />
            </Dialog>
        </div>
    </div>
</template>

<script>
import Divider from "primevue/divider";
import Dialog from "primevue/dialog";
import { mapActions } from "vuex";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";
import userMixin from "../../store/mixins/user";
import emailVerifyMixin from "../../components/auth/emailVerify/emailVerify";
import AuthWindow from "../../components/auth/authWindow/AuthWindow.vue";

import {
    getReauthenticationProvider,
    FIREBASE_AUTH_PROVIDERS,
} from "../../auth/firebase";
import { getShortMonthName, getDateSuffix, titleCase } from "../../utility";

export default {
    mixins: [userMixin, emailVerifyMixin],
    components: {
        Header,
        Footer,
        Divider,
        Dialog,
        AuthWindow,
    },
    data() {
        return {
            ready: false,
            showEmailVerificationMessage: false,
            showReauthWindow: false,
            accountType: null,
            showUpdatePasswordDialog: false,
            showPasswordOptions: false,
        };
    },
    watch: {
        loggedIn() {
            if (!this.loggedIn) {
                this.$router.push({
                    name: "Home",
                });
            }
        },
        activeUser() {
            if (this.showAuthWindow) this.showAuthWindow = false;
        },
    },
    mounted() {
        if (!this.loggedIn) {
            this.$router.push({
                name: "Login",
                params: {
                    redirectOnLogin: JSON.stringify({
                        name: "Preferences",
                    }),
                },
            });
        } else {
            this.accountType = getReauthenticationProvider();
            if (this.accountType == FIREBASE_AUTH_PROVIDERS.email)
                this.showPasswordOptions = true;
            if (!this.activeUser.emailVerified) {
                // Do something
            }
        }

        this.ready = true;
    },
    computed: {
        joinDate() {
            if (this.activeUser && this.activeUser.accountCreated) {
                const joined = new Date(this.activeUser.accountCreated);
                const formatted = `${getShortMonthName(
                    joined.getMonth()
                )} ${joined.getDate()}<span class="am-dateSuffix">${getDateSuffix(
                    joined.getDate()
                )}</span>, ${joined.getFullYear()}`;
                return formatted;
            }
            return null;
        },
        formattedAccountType() {
            if (this.activeUser && this.activeUser.accountType)
                return titleCase(this.activeUser.accountType);
            return "";
        },
    },
    methods: {
        ...mapActions(["reauthenticate", "deleteUser"]),
        hideDialog(value) {
            if (!value) this.showReauthWindow = false;
        },
        hideupdatePasswordDialog(value) {
            if (!value) this.showUpdatePasswordDialog = false;
        },
        async reauthenticateUser() {
            if (this.loggedIn) {
                // Has Recently logged in, if so just return true, else go through reauthentication flow
                if (this.activeUser.hasRecentlyLoggedIn) return true;

                const currentProvider = getReauthenticationProvider();
                if (currentProvider) {
                    if (currentProvider == FIREBASE_AUTH_PROVIDERS.email) {
                        this.showReauthWindow = true;
                    } else {
                        const res = await this.reauthenticate({
                            provider: currentProvider,
                        });
                        if (res) {
                            // successful reauthentication
                            return true;
                        }
                    }
                }
            }
        },
        onReauthenticated(success) {
            if (success) {
                this.showReauthWindow = false;
            }
        },
        async updatePassword() {
            await this.reauthenticateUser();
            if (this.activeUser.hasRecentlyLoggedIn) {
                this.showUpdatePasswordDialog = true;
            } else {
                // User is not recently authenticated for some reason despite function above
                null;
            }
        },
        onUpdatePassword() {
            this.showUpdatePasswordDialog = false;
        },
        resendVerificationEmail() {
            this.sendVerification();
        },
        async onDeleteAccountClick() {
            const isAuthenticated = await this.reauthenticateUser();
            if (isAuthenticated) {
                this.$confirm.require({
                    message:
                        "Are you sure you wish to delete this account? This action cannot be undone.",
                    header: "Delete Account",
                    acceptClass: "p-button-danger",
                    icon: "pi pi-exclamation-triangle",
                    accept: async () => {
                        const res = await this.deleteUser();
                        if (res) {
                            this.$toast.add({
                                severity: "success",
                                group: "top-center",
                                summary: "Account Deleted",
                                detail: "Your account has been deleted",
                                life: 5000,
                            });
                        } else {
                            this.$toast.add({
                                severity: "error",
                                group: "top-center",
                                summary: "Problem Deleting Account",
                                detail:
                                    "Unknown Error, Your account may not have been deleted.",
                                life: 5000,
                            });
                        }
                    },
                    reject: () => {
                        null;
                    },
                });
            }
        },
    },
};
</script>

<style src="./accountManagement.css"></style>
