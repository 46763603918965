export default {
    data() {
        return {};
    },
    methods: {
        async extensionExists() {
            try {
                // return true
                if (navigator.userAgent.toLowerCase().includes("firefox")) {
                    this.postMessageToExtension("getExtensionID");
                    await this.addExtensionMessageListener("extensionID", 500);
                    return true;
                }
                const res = await fetch(
                    process.env.VUE_APP_EXTENSION_RESOURCE,
                    {
                        method: "HEAD",
                    }
                );
                return res.ok;
            } catch (e) {
                return false;
            }
        },
        addExtensionMessageListener(eventName, timeout) {
            return new Promise((resolve, reject) => {
                function listener({ data }) {
                    if (data.name === eventName) {
                        window.removeEventListener("message", listener);
                        resolve(data);
                    }
                }
                window.addEventListener("message", listener);
                if (timeout)
                    setTimeout(() => {
                        window.removeEventListener("message", listener);
                        reject();
                    }, timeout);
            });
        },
        async isExtensionUser() {
            return await this.extensionExists();
        },
        async shouldPromptUser() {
            if (
                new Date().toDateString() === localStorage.getItem("lastPrompt")
            ) {
                return false;
            }
            if (await this.isExtensionUser()) {
                localStorage.setItem("lastPrompt", new Date().toDateString());
            }
            return true;
        },
        postMessageToExtension(eventName, data = {}) {
            const msgToPost = JSON.parse(
                JSON.stringify({
                    name: eventName,
                    ...data,
                })
            );
            if (window.parent != window.self) {
                window.parent.postMessage(msgToPost, "*");
            } else {
                window.postMessage(msgToPost, window.location.origin);
            }
        },
    },
};
