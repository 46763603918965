export default {
    data() {
        return {
            activeUser: null,
            previousUser: null,
            onUserChange: null,
        };
    },
    created() {
        this.activeUser = this.$store.state.user.user;
    },
    mounted() {},
    computed: {
        loggedIn() {
            if (this.activeUser) return true;
            return false;
        },
    },
    watch: {
        "$store.state.user.user": function(nv, ov) {
            this.activeUser = nv;
            this.previousUser = ov;
            if (this.onUserChange && typeof this.onUserChange === "function") {
                if (this.onUserChange.length == 1) {
                    this.onUserChange(nv);
                } else if (this.onUserChange.length == 2) {
                    this.onUserChange(nv, ov);
                } else this.onUserChange();
            }
        },
    },
    methods: {
        fireGoogleOneTapPrompt() {
            if (window.google) {
                try {
                    window.google.accounts.id.prompt(notification => {
                        if (
                            notification.isNotDisplayed() ||
                            notification.isSkippedMoment()
                        ) {
                            console.error("Problem -", notification);
                        }
                    });
                } catch (e) {
                    null;
                }
            }
        },
        formattedRecipeList(recipeObj) {
            if (recipeObj && Object.keys(recipeObj).length > 0) {
                const out = [];
                for (const i in recipeObj) {
                    const newRecipe = recipeObj[i];
                    newRecipe.uuid = i;

                    // Formatting Fixes
                    newRecipe.calories = parseInt(newRecipe.calories);
                    newRecipe.carbohydrates = parseInt(newRecipe.carbohydrates);
                    out.push(newRecipe);
                }

                return out;
            }
            return false;
        },
    },
};
