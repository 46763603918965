<template>
    <div id="privacy-policy-root" class="footerPageRoot">
        <div class="footer-page-outer-wrapper">
            <div class="footer-page-header">
                <Header />
            </div>
            <div class="footer-page-body outer-wrapper page-body">
                <h1 class="page-title">Contact Us</h1>

                Cooking.Freshy.com, the official website of Cooking Freshy, is
                committed to making your user experience pleasant and productive
                while using this site. Should you have any additional questions,
                comments, or compliments, you can also reach us via email at
                <a href="mailto:cookingcontactus@freshy.com" target="_top"
                    >cookingcontactus@freshy.com</a
                >
                You can also send snail mail to the address below. We welcome
                your feedback!
                <p style="color: #2464cf; padding-top: 50px;"></p>
                <p>
                    Tightrope Interactive<br />
                    588 Sutter St #551<br />
                    San Francisco, CA 94108<br />
                </p>
                Thanks for your support! -The Cooking Freshy Team
            </div>
            <div class="footer-page-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";

export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<style scoped src="./footerPage.css"></style>
