<template>
    <div id="feature-article" class="feature-article-section">
        <div class="home-header-title">
            <h2>
                <a :href="idea.link">
                    {{ idea.title && idea.title.rendered }}
                </a>
                <span class="feature-more">
                    <a :href="idea.link">Read More</a>
                </span>
            </h2>
        </div>

        <div
            v-if="articleRecipes?.length"
            class="home-hero-carousel home-hero-section p-grid"
        >
            <div
                v-for="recipe in articleRecipes"
                :key="recipe.uuid"
                class="p-col-12 p-md-3"
                style="padding: 0;"
            >
                <article-card
                    :recipe="recipe"
                    :title="recipe.title"
                    :image="recipe.image"
                    :calories="
                        parseInt(
                            recipe.nutrients && recipe.nutrients.calories
                                ? recipe.nutrients.calories
                                : null
                        )
                    "
                    :carbs="
                        recipe.nutrients && recipe.nutrients.carbohydrateContent
                            ? recipe.nutrients.carbohydrateContent
                            : null
                    "
                    :cooktime="recipe.total_time"
                    :url="null"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ArticleCard from "./recipeCards/ArticleCard";
import { getLatestIdeas, getRecipesFromApi } from "../fetchFunctions";

export default {
    props: ["image", "title", "uuid", "recipe"],
    components: {
        ArticleCard,
    },
    data() {
        return {
            idea: {},
            articleRecipes: null,
        };
    },
    computed: {
        formattedPublishedDate() {
            return new Date(
                this.idea && `${this.idea.date}Z`
            ).toLocaleDateString("default", {
                month: "long",
                day: "numeric",
            });
        },
    },
    async created() {
        const ideas = await getLatestIdeas({
            count: 1,
            // only get posts w/ category "What to Eat This Week"
            params: { categories: "3" },
        });
        this.idea = ideas.length && ideas[0];
        const mostRecentIdeaRecipes = (
            (this.idea && this.idea.recipes) ||
            ""
        ).split(",");
        if (mostRecentIdeaRecipes.length) {
            this.loadArticleData(mostRecentIdeaRecipes);
        }
    },
    methods: {
        async loadArticleData(items) {
            const res = await getRecipesFromApi(items);
            if (res && res.status && res.results) {
                const recipes = [];
                for (const i in res.results) {
                    if (res.results[i].uuid && res.results[i].data) {
                        res.results[i].data.uuid = res.results[i].uuid;
                        recipes.push(res.results[i].data);
                    }
                }
                if (recipes.length > 0)
                    this.articleRecipes = recipes.slice(0, 4);
            }
        },
    },
};
</script>

<style scoped>
#feature-article {
    margin-bottom: 30px;
}

/*
#feature-article .home-hero-section {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
*/

.article-card-date {
    color: #ffc000;
    font-size: 12px;
    font-weight: bold;
    border-top: 1px solid #d7d7d7;
    width: fit-content;
    padding: 10px 25px 0px;
    margin: 0 auto;
}

.article-card-title {
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: bold;
    color: #3d3d3d;
    margin-bottom: 10px;
}

.article-card-right {
    padding: 25px 15px;
}

/* Feature Article */
.feature-blurb :deep(p),
.feature-more a {
    color: #73716a;
    font-family: sans-serif;
    font-size: 1.25rem;
    line-height: 36px;
}

.home-header-title a {
    text-decoration: none;
}

.home-header-title h3 {
    margin: 0px 0px 25px 0;
    font-weight: normal;
    font-family: sans-serif;
    color: #73716a;
    font-size: 80%;
}

#feature-article a,
#feature-article a:hover {
    color: #0557e7;
}

.feature-more {
    margin-bottom: 30px;
    display: block;
}

.feature-more a {
    font-weight: bold;
}
</style>
