<template>
    <div class="recipe-save-btn-root">
        <OverlayAuthWindow
            v-if="showLogin"
            @closed="hideDialog"
            @login="onSaveRecipe(recipe)"
            @sendToExtension="sendRecipeToExt(recipe)"
        ></OverlayAuthWindow>
        <transition name="ultrafastFade" mode="out-in">
            <div
                v-if="!isRemovable"
                class="p-shadow-2 recipe-card-saveBtn btn noUserSelect"
                @click.prevent.stop="onSaveRecipe(recipe)"
            >
                <i class="pi pi-heart"></i> <span>Save Recipe</span>
            </div>

            <div
                v-else-if="showRemoveBtn"
                class="p-shadow-2 recipe-card-removeBtn btn noUserSelect"
                @click.prevent.stop="onRemoveRecipe(recipe)"
            >
                <i class="pi pi-trash"></i> <span>Remove Recipe</span>
            </div>

            <div v-else class="p-shadow-2 recipe-card-saveBtn btn noUserSelect">
                <img src="/cooking/assets/images/savedIcon.svg" alt="Saved" />
                <span>Saved</span>
            </div>
        </transition>
    </div>
</template>

<script>
import recipeMixin from "../componentMixins/recipeMixin";
import extensionMixin from "../componentMixins/extensionMixin";
import userMixin from "../../store/mixins/user";
import OverlayAuthWindow from "../auth/authWindow/OverlayAuthWindow";

export default {
    name: "recipe-save-btn",
    mixins: [recipeMixin, userMixin, extensionMixin],
    props: ["recipe", "showRemove", "bypassLogin"],
    components: {
        OverlayAuthWindow,
    },
    data() {
        return {
            isRemovable: false,
            showLogin: false,
        };
    },
    mounted() {
        if (
            this.activeUser &&
            this.loggedIn &&
            this.activeUser.recipeList &&
            this.activeUser.recipeList[this.recipe.uuid]
        ) {
            this.isRemovable = true;
        }
    },
    computed: {
        showRemoveBtn() {
            if (this.showRemove) return this.showRemove;
            return null;
        },
    },
    watch: {
        loggedIn() {
            if (
                this.activeUser &&
                this.loggedIn &&
                this.activeUser.recipeList &&
                this.activeUser.recipeList[this.recipe.uuid]
            ) {
                this.isRemovable = true;
            } else this.isRemovable = false;
        },
    },
    methods: {
        async onSaveRecipe(recipe) {
            if (this.activeUser && this.loggedIn) {
                await this.onSaveRecipeClick(recipe);
                this.isRemovable = true;
                this.showLogin = false;
            } else if (
                (this.bypassLogin || !(await this.shouldPromptUser())) &&
                (await this.isExtensionUser())
            ) {
                if (!recipe.recipedata) recipe.recipedata = { ...recipe };
                this.postMessageToExtension("saveRecipe", { recipe });
            } else {
                this.showLogin = true;
            }
        },
        async onRemoveRecipe(recipe) {
            if (
                this.activeUser &&
                this.loggedIn &&
                this.activeUser.recipeList &&
                this.activeUser.recipeList[recipe.uuid]
            ) {
                await this.onRemoveRecipeClick(recipe.uuid);
                // this.isRemovable = false
            }
        },
        hideDialog() {
            this.showLogin = false;
        },
        async sendRecipeToExt(recipe) {
            this.showLogin = false;
            if (await this.isExtensionUser()) {
                this.postMessageToExtension("saveRecipe", { recipe });
            }
        },
    },
};
</script>

<style scoped src="./recipeSaveBtn.css"></style>
