<template>
    <div class="yfeedCard recipeCardRoot">
        <a
            :href="feedData.link"
            target="_blank"
            v-if="feedData && feedData.link"
            class="recipe-card-wrapper p-grid p-shadow-1"
            @click="onClick()"
        >
            <div
                v-if="feedData.image && feedData.image.url"
                class="recipe-card-left"
                :class="{
                    'p-col-12': feedData.image,
                    'p-d-none': !feedData.image,
                }"
            >
                <img class="recipe-card-img" :src="feedData.image.url" />
            </div>
            <div
                class="recipe-card-right p-col-12"
                :class="{
                    'p-col-12': feedData.image,
                    'p-col-12': !feedData.image,
                }"
            >
                <div class="recipe-card-data">
                    <div
                        v-if="formattedTitle"
                        class="recipe-card-title recipe-card-data"
                    >
                        <span class="splash-card-tag"
                            >{{ formattedContentType
                            }}<span v-if="formattedSource">
                                {{ formattedSource }}</span
                            ></span
                        >
                        <div class="yfeedTitle">
                            <span v-snip="2" :title="formattedTitle">{{
                                formattedTitle
                            }}</span>
                        </div>
                    </div>
                    <div
                        v-if="formattedContentType"
                        class="recipe-card-meta"
                    ></div>
                    <div
                        style="display: none;"
                        v-if="feedData.description"
                        class="recipe-card-meta"
                    >
                        <span> {{ feedData.description }}</span>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { titleCase } from "../../utility";

export default {
    name: "recipe-card",
    props: ["feedData"],
    data() {
        return {};
    },
    computed: {
        formattedTitle() {
            if (this.feedData && this.feedData.title)
                return this.feedData.title;
            return null;
        },
        formattedContentType() {
            if (
                this.feedData &&
                this.feedData.category &&
                this.feedData.category == "nutrition"
            )
                return "Food & Wellness";
            if (this.feedData && this.feedData.category)
                return titleCase(this.feedData.category);
            return null;
        },
        formattedSource() {
            if (
                this.feedData &&
                this.feedData.source &&
                this.feedData.source.title
            )
                return this.feedData.source.title;
            return null;
        },
    },
    mounted() {
        if (this.feedData && this.feedData.category && this.$route) {
            this.$trTracking.fireEvent({
                action: "impression",
                option: this.$route.name,
                feed: `yahoo newsfeed - ${this.feedData.category}`,
                ads: 1,
            });
        }
    },
    methods: {
        onClick() {
            this.$emit("onClick", this.feedData);
            this.$trTracking.fireEvent({
                action: "click",
                option: this.$route.name,
                feed: `yahoo newsfeed - ${this.feedData.category}`,
            });
        },
    },
};
</script>

<style scoped src="./recipeCard.css"></style>
