<template>
    <div id="app_root">
        <Toast class="p-d-print-none" />
        <Toast
            position="top-center"
            group="top-center"
            class="p-d-print-none"
        />
        <ConfirmDialog
            :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
            :style="{ width: '50vw' }"
            class="p-d-print-none"
        ></ConfirmDialog>
        <email-verify-popup class="p-d-print-none" />
        <router-view :key="$route.fullPath"></router-view>
    </div>
</template>

<script>
import Toast from "primevue/toast";
import ConfirmDialog from "primevue/confirmdialog";
import { mapActions } from "vuex";
import userMixin from "./store/mixins/user";
import EmailVerifyPopup from "./components/auth/emailVerify/EmailVerifyPopup.vue";

export default {
    mixins: [userMixin],
    components: { EmailVerifyPopup, Toast, ConfirmDialog },
    name: "App_Main",
    data() {
        return {
            currentBanner: null,
            appRoot: null,
        };
    },
    created() {
        // this.reloadUser()
    },
    mounted() {
        this.$nextTick(() => {
            this.appRoot = document.querySelector("#app_root");
            window.addEventListener("scroll", () => {
                try {
                    if (!this.currentBanner)
                        this.currentBanner = document.querySelector(
                            "#ga_sticky_bottom"
                        );
                    if (this.currentBanner) {
                        const currentFullPosition =
                            window.scrollY + window.innerHeight;
                        const documentHeight = this.appRoot.offsetHeight;
                        const FOOTER_OFFSET = 125;
                        const offsetLimit = documentHeight - FOOTER_OFFSET;

                        if (currentFullPosition >= offsetLimit) {
                            // footer is visible
                            const offsetBottom =
                                currentFullPosition - offsetLimit;
                            this.currentBanner.style.bottom = `${offsetBottom}px`;
                        } else {
                            // footer is not visible
                            this.currentBanner.style.bottom = `0px`;
                        }
                    }
                } catch (e) {
                    null;
                }
            });
        });
    },
    methods: {
        ...mapActions(["reloadUser"]),
    },
};
</script>

<style>
@import "./global.css";
</style>
