import { getTypeTag } from "@/utility";

// Our Events endpoint /api/events

// Type 1=search 2=ad click 3=organic click
function yahooXMLClickEvent(type, searchInfo) {
    fetch(
        `/xml/ping.php?q=${searchInfo.keyword}&source_tag=${
            searchInfo.source_tag
        }&type_tag=${getTypeTag(searchInfo.type_tag)}&site=${searchInfo.site}${
            window.location.pathname
        }&etype=${type}&user_country=${searchInfo.user_country}&market=${
            searchInfo.market
        }`
    );
}

export { yahooXMLClickEvent };
