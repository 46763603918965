<template>
    <div id="article-list" class="article-list-section">
        <div
            v-if="ideas.length"
            id="article-list-carousel"
            class="home-hero-carousel home-hero-section p-grid"
        >
            <span
                v-for="(idea, index) in ideas"
                :key="index"
                class="article-card"
            >
                <a
                    class="article-card-link article-card-link-newtab p-shadow-1"
                    :href="idea.link"
                >
                    <div class="article-card-left">
                        <img
                            class="article-card-img"
                            :src="getIdeaImage(idea)"
                        />
                    </div>
                    <div class="article-card-right">
                        <div class="article-card-data">
                            <div class="article-card-title">
                                <span title="article title">{{
                                    idea.title?.rendered
                                }}</span>
                            </div>
                            <div class="article-card-date">
                                <span>{{ formattedPublishedDate(idea) }}</span>
                            </div>

                            <div class="article-author">
                                <span class="author-tag">{{
                                    idea.article_author
                                }}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </span>
        </div>
    </div>
</template>

<script>
import { getLatestIdeas } from "../fetchFunctions";
import { shuffleArr } from "../utility";

export default {
    props: ["maxNum"],
    data() {
        return {
            ideas: [],
        };
    },
    async created() {
        const latestIdeas = await getLatestIdeas({ count: 10, embed: true });
        this.ideas =
            latestIdeas.length && latestIdeas ? shuffleArr(latestIdeas) : null;
        if (this.maxNum && this.ideas && this.ideas.length > this.maxNum)
            this.ideas = this.ideas.slice(0, this.maxNum);
        else if (this.ideas && this.ideas.length > 2)
            this.ideas = this.ideas.slice(0, 2);
    },
    methods: {
        getIdeaImage(idea) {
            const featMedia = idea?._embedded?.["wp:featuredmedia"];
            return featMedia?.length && featMedia[0].source_url;
        },
        formattedPublishedDate(idea) {
            return new Date(idea && `${idea.date}Z`).toLocaleDateString(
                "default",
                {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                }
            );
        },
    },
};
</script>

<style scoped>
#feature-article {
    margin-bottom: 30px;
}
.article-card-left {
    overflow: hidden;
}

.article-card-left img {
    width: auto;
    height: 100%;
    margin-left: -10px;
}
img.article-card-img {
    display: block;
}
.article-card-data {
    padding: 11px 10px;
    height: 100%;
}
.article-card-date {
    width: 100%;
    font-size: 0.6rem;
    line-height: 1rem;
}
.article-card-title {
    font-size: 14px;
    font-weight: 700;
    overflow: hidden;
    height: 30px;
    line-height: 16px;
    margin-bottom: 5px;
}
a.article-card-link {
    width: 100%;
    height: 100%;
    border: 1px solid #c3c3c3;
    text-decoration: none;
    color: #3d3d3d;
    display: grid;
    grid-template-columns: 48% auto;
    margin: 8px;
}

a.article-card-link:hover {
    box-shadow: 0 2px 4px -1px rgb(0 0 0/20%), 0 4px 5px 0 rgb(0 0 0/14%),
        0 1px 10px 0 rgb(0 0 0/12%);
}

#article-list-carousel {
    margin-left: 5px;
    height: 100%;
}

.article-card-data .article-author {
    font-size: 0.7rem;
    color: #ffc000;
    font-weight: bold;
}

.article-card {
    height: 100%;
    padding: 0px 10px;
    flex: 1;
}

#article-list {
    height: 98%;
    max-height: 121px;
}
</style>
