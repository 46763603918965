<template>
    <div id="not-found-root">
        <div id="not-found-root-wrapper">
            <div id="not-found-header">
                <Header />
            </div>
            <div id="not-found-body">
                <div id="not-found-body-wrapper">
                    <h1>Page Not Found</h1>
                    <p>
                        Click the button below to go back to the homepage. On
                        the homepage, you can view, search or print recipes on
                        Cooking Freshy Recipes
                    </p>

                    <Button
                        id="not-found-button"
                        label="Back to Homepage"
                        class="p-button-outlined p-button-lg"
                        iconPos="right"
                        icon="pi pi-chevron-right"
                        @click="$router.push({ name: 'Home' })"
                    />
                </div>
            </div>
            <div id="not-found-footer">
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
import Button from "primevue/button";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";

export default {
    components: {
        Header,
        Footer,
        Button,
    },
};
</script>

<style scoped src="./notFound.css"></style>
