import { getYFeed } from "../../fetchFunctions";
import { getTypeTag } from "../../utility";

export const yfeeds = {
    state: {
        yFeed: null,
    },
    mutations: {
        setYFeed(state, data) {
            state.yFeed = { timestamp: Date.now(), data };
        },
    },
    actions: {
        /**
         * Checks to see if topNews is loaded, if not or is stale, reload it
         */
        async loadYFeed({ commit, state, rootState }) {
            const feed = state.yFeed;
            const RELOAD_TIME = 15 * 60 * 1000 * 1000;
            const expiration_timestamp = Date.now() - RELOAD_TIME;
            let typetag =
                rootState.attribution && rootState.attribution.typeTag;
            if (!typetag) typetag = getTypeTag("Y225_F1_207321");

            if (
                !feed ||
                !feed.data ||
                !feed.timestamp ||
                feed.timestamp < expiration_timestamp
            ) {
                const newFeed = await getYFeed();
                if (newFeed) {
                    newFeed.data = newFeed.data.filter(
                        x => Object.keys(x).length
                    );
                    newFeed.data.map(el => {
                        el.dataReturnType = "yfeed";
                        if (!el.link) return el;
                        const link = new URL(el.link);
                        link.searchParams.set("type", typetag);
                        el.link = link.toString();
                        return el;
                    });
                    commit("setYFeed", newFeed);
                }
            }
        },
    },
};
