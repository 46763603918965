import userMixin from "../../store/mixins/user";
import { titleCase } from "../../utility";

export default {
    mixins: [userMixin],
    methods: {
        async onSaveRecipeClick(data, failed = false) {
            if (this.activeUser && this.loggedIn) {
                try {
                    const url = data.url || data.canonical;
                    if (data && data.uuid && url && !failed) {
                        await this.activeUser.addRecipe(
                            data.uuid,
                            url,
                            data.recipedata || data
                        );
                        this.$toast.add({
                            severity: "success",
                            summary: "Recipe Saved",
                            detail: "Recipe has been saved to recipe book.",
                            life: 3000,
                        });
                        this.$emit("savedRecipe", data);
                    } else if (data && data.url && failed) {
                        await this.activeUser.addRecipe(null, url, null);
                        this.$toast.add({
                            severity: "success",
                            summary: "Recipe Saved",
                            detail: "Recipe has been saved to recipe book.",
                            life: 3000,
                        });
                        this.$emit("savedRecipe", data);
                    } else {
                        throw "Can't save recipe";
                    }
                } catch (e) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Recipe not saved",
                        detail:
                            e.name === "Duplicate Recipe"
                                ? e.message
                                : "Cannot save recipe at this time. Please try again later.",
                        life: 3000,
                    });
                    throw e;
                }
            } else if (!this.loggedIn || !this.activeUser) {
                this.$emit("needLogin", true);
            }
        },
        async onRemoveRecipeClick(uuid) {
            if (
                this.activeUser &&
                uuid &&
                this.loggedIn &&
                this.activeUser.recipeList &&
                this.activeUser.recipeList[uuid]
            ) {
                try {
                    const x = this.activeUser.removeRecipe(uuid);
                    if (x) {
                        this.$toast.add({
                            severity: "success",
                            summary: "Recipe Removed",
                            detail: "Recipe has been removed from recipe book.",
                            life: 2000,
                        });
                        this.$emit("removedRecipe", uuid);
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Recipe not removed",
                            detail:
                                "Cannot remove recipe at this time. Please try again later.",
                            life: 3000,
                        });
                    }
                } catch (e) {
                    this.$toast.add({
                        severity: "error",
                        summary: "Recipe not removed",
                        detail:
                            "Cannot remove recipe at this time. Please try again later.",
                        life: 3000,
                    });
                }
            } else if (!this.loggedIn || !this.activeUser) {
                this.$emit("needLogin", true);
            }
        },
        async onRecipeSync({ recipes }) {
            try {
                if (!Object.keys(recipes).length) return;
                await this.activeUser.batchAddRecipes(recipes);
                this.$toast.add({
                    severity: "success",
                    summary: "Recipes Saved",
                    detail: "Your recipes have been saved to recipe book.",
                    life: 3000,
                });
            } catch (e) {
                this.$toast.add({
                    severity: "error",
                    summary: "Recipes not saved",
                    detail:
                        "Cannot save your recipes at this time. Please try again later.",
                    life: 3000,
                });
                throw e;
            }
        },
        /**
         * Function to parse through nutrient keys to get formatted names
         * THIS FUNCTION IS A TEMPORARY FIX AND IS NOT GUARENTEED IN ITS FUNCTIONALITY
         * @param {String} str string to parse and format
         * @returns {String} parsed and formatted string
         */
        parseNutrientString(str) {
            // Common Words - Should all be lowercase
            const commonWords = [
                "calories",
                "from",
                "fat",
                "fiber",
                "content",
                "equivalents",
                "vitamin",
            ];

            let newStr = str.toLowerCase();

            // Deliniate common words and add correct spaces
            for (const i in commonWords) {
                const replaceString = newStr.replace(
                    commonWords[i],
                    `<<_>>${commonWords[i]}<<_>>`
                );

                if (replaceString.includes("<<_>>")) {
                    const separatedStr = replaceString.split("<<_>>");
                    if (separatedStr && separatedStr[0] == "")
                        separatedStr.shift();

                    if (separatedStr[0] && separatedStr[0] == commonWords[i]) {
                        separatedStr[0] += " ";
                    } else if (separatedStr.indexOf(commonWords[i]) > -1) {
                        const index = separatedStr.indexOf(commonWords[i]);
                        separatedStr[index] = ` ${separatedStr[index]}`;
                    }
                    newStr = separatedStr.join("");
                }
            }

            // Vitamin exception. Remove iu
            if (newStr.includes("vitamin")) newStr = newStr.replace("iu", "");

            // Return TitleCap
            return titleCase(newStr);
        },
        formatTimeFromMinutes(time) {
            if (time && !isNaN(time)) {
                const hours = Math.floor(time / 60);
                const minutes = time % 60;
                if (hours && hours != 0) return `${hours}h${minutes}m`;
                return `${minutes}m`;
            }
            return null;
        },
    },
};
