<template>
    <div id="contact-us-root" class="footerPageRoot">
        <div class="footer-page-outer-wrapper">
            <div class="footer-page-header">
                <Header />
            </div>
            <div class="footer-page-body outer-wrapper page-body">
                <h1 class="page-title">Terms of Use</h1>

                <p>
                    ATTENTION PLEASE READ THIS AGREEMENT CAREFULLY BEFORE
                    ACCESSING THE SITE AND DOWNLOADING ANY CONTENT. IF YOU USE
                    THE SITE OR DOWNLOAD CONTENT YOU AGREE TO EACH OF THE
                    FOLLOWING TERMS AND CONDITIONS.
                </p>

                <p>
                    freshy.com (“Website”) is a website owned and operated by
                    Tightrope Interactive, Inc.(“Company”).
                </p>

                <p>
                    This is a legally binding contract between you and the
                    Company. By downloading, installing, copying, running,
                    opting into notifications or using our software
                    (collectively “Content”) available via our Website, and
                    other websites we own that post a link to this agreement
                    (collectively the “Sites”), you are agreeing to be bound by
                    the terms of this Agreement. You are also agreeing to our
                    <a href="/privacy-policy" target="blank">Privacy Policy</a>.
                    If you do not agree to our terms, you must navigate away
                    from our Sites, you may not download the Content, and you
                    must destroy any copies of the Content in your possession.
                </p>

                <p>
                    If you are under 18, you must have your parent or guardian’s
                    permission before you use our Sites or download Content. In
                    an effort to comply with the Children’s Online Privacy
                    Protection Act, we will not knowingly collect personally
                    identifiable information from children under the age of 13.
                </p>

                <p>
                    This Agreement may be modified by us from time to time. If
                    you breach any term in this Agreement your right to use the
                    Sites and Content will terminate automatically.
                </p>

                <h3>1. The Download Process</h3>
                <p>
                    Your download and software installation is managed by your
                    web browser. You will be prompted by your web browser to
                    install a browser extension powered by the Company. Upon
                    acceptance of your browser dialogs to install – our
                    extensions will be accessible for your use through your web
                    browser. During the installation process, the extensions may
                    change your search settings, new tab settings, add browser
                    action icons and add additional features to your web
                    browser.
                </p>

                <h3>2. Delivery of Advertising</h3>
                <p>
                    By accessing the Sites and downloading the Content, you
                    hereby grant us permission to display promotional
                    information, advertisements, and offers for third party
                    products or services (collectively “Advertising”). The
                    Advertising may include, without limitation, content, offers
                    for products or services, data, links, articles, graphic or
                    video messages, text, software, music, sound, graphics or
                    other materials or services. The timing, frequency,
                    placement and extent of the Advertising changes are
                    determined in our sole discretion. You further grant us
                    permission to collect and use certain aggregate information
                    in accord with our
                    <a href="/privacy-policy/" target="blank">Privacy Policy</a
                    >.
                </p>

                <p>
                    Upon accessing our Sites, you may be asked to opt into our
                    optional browser push notifications. If you opt into our
                    optional browser push notifications; you herby grant us
                    permission to send promotional information, advertisements
                    and offers for third party products or services to you via
                    browser push notifications. You can unsubscribe from these
                    notifications by following our
                    <a href="/unsubscribe/" target="blank"
                        >unsubscribe information here.</a
                    >
                </p>

                <h3>3. Your Obligations</h3>
                <p>
                    You may not use another person’s name or information on our
                    Sites. You agree to use the Sites and Content only for
                    lawful purposes. You agree not to take any action that might
                    compromise the security of the Sites, render the Sites
                    inaccessible to others or otherwise cause damage to the
                    Sites or the Content. You agree not to use the Sites in any
                    manner that might interfere with our or our Partner’s
                    rights. You represent and warrants that (a) you are the
                    owner or an authorized user of the computer that the Content
                    is installed on, (b) you will use the Content, and the Sites
                    only for lawful purposes, and will comply at all times with
                    all applicable federal, state, and local laws and
                    regulations, and (c) you are at least thirteen years of age.
                    Persons under thirteen years of age may not use the Content.
                    You agree not to use any automated or manual process to
                    interfere with, modify, or attempt to interfere with or
                    modify the Content, except to uninstall the same as provided
                    herein. You acknowledge sole responsibility for installing
                    appropriate anti-virus software and other security measures
                    on your computer.
                </p>

                <h3>4. Grant of License</h3>
                <p>
                    We grant you a non-exclusive, non-transferable and
                    non-assignable license to use the Content within the United
                    States of America, in accordance with the terms and
                    conditions of this Agreement. Other than the rights
                    expressly granted hereunder, no other right is granted. You
                    agree not to add to, subtract from or otherwise modify,
                    translate, disassemble, decompile, reverse engineer, or
                    create derivative works of the Content. You may not rent,
                    lease, sell, redistribute, sublicense or otherwise transfer
                    the Content. You may make only such copies of the Content as
                    are reasonably necessary for your own use, and any copy made
                    by you must bear the same copyright and other proprietary
                    notices that appear on the copy furnished by us.
                </p>

                <h3>5. Termination</h3>
                <p>
                    This license will immediately terminate if you violate any
                    provision of this Agreement. We may also terminate this
                    license at any time without notice.
                </p>

                <h3>6. Ownership</h3>
                <p>
                    We own all intellectual property rights in and to the
                    Content. This license is not a sale and does not render you
                    the owner of a copy of the Content. Ownership of the Content
                    and all components and copies thereof will at all times
                    remain with us, regardless of who may be deemed the owner of
                    the tangible media on which the Content is copied, encoded
                    or otherwise fixed.
                </p>

                <h3>7. Disclaimer of Warranties</h3>
                <p>
                    WE PROVIDE ALL CONTENT “AS IS,” “WITH ALL FAULTS,” AND
                    WITHOUT ANY WARRANTY WHATSOEVER. ALL SITES ARE PROVIDED ON
                    AN “AS IS, AS AVAILABLE” BASIS. WE DISCLAIM ALL WARRANTIES,
                    EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, TITLE
                    OR NON-INFRINGEMENT. WE DO NOT WARRANT ANY PART OF THE
                    CONTENT NOR DO WE REPRESENT THE CONTENT WILL MEET YOUR NEEDS
                    OR THAT ITS OPERATION WILL BE UNINTERRUPTED OR ERROR FREE.
                    THE ENTIRE RISK AS TO THE QUALITY AND PERFORMANCE OF THE
                    CONTENT IS WITH YOU.
                </p>

                <h3>8. Exclusive Remedy</h3>
                <p>
                    IF YOU ARE DISSATISFIED WITH THE SITES, THE CONTENT OR THESE
                    TERMS AND CONDITIONS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                    DISCONTINUE USING THE SITES AND CONTENT.
                </p>

                <h3>9. Limitations of Liability</h3>
                <p>
                    WE ARE NOT LIABLE TO YOU OR ANY OTHER PERSON FOR ANY
                    INCIDENTAL, CONSEQUENTIAL, SPECIAL, INDIRECT, PUNITIVE OR
                    EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, EQUIPMENT
                    DOWNTIME, LOSS OF DATA, OR LOST PROFITS, EVEN IF WE HAVE
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE
                    THAT OUR AGGREGATE LIABILITY, HOWSOEVER ARISING OUT OF OR
                    RELATING TO THIS AGREEMENT OR THE SUBJECT MATTER HEREOF,
                    SHALL, IN NO EVENT, EXCEED ONE DOLLAR (US$1.00). BY
                    INSTALLING OR USING THE CONTENT, YOU ACCEPT SOLE
                    RESPONSIBILITY FOR ALL CONSEQUENCES ARISING THEREFROM AND
                    ACKNOWLEDGES THAT NO CLAIM WHATSOEVER WILL BE MADE AGAINST
                    US OR OUR LICENSORS, DISTRIBUTORS, AGENTS, EMPLOYEES OR
                    AFFILIATES.
                </p>

                <h3>10. Third-Party Advertisers</h3>
                <p>
                    We make no representations or warranties concerning
                    third-party or Partner Offers, you agree that we are not
                    responsible or liable for any loss or damage of any sort
                    incurred, or as the result of the delivery or display of the
                    Offers. WE ARE NOT RESPONSIBLE FOR THE TERMS AND CONDITIONS
                    OF ANYTHIRD-PARTY OR PARTNER WEBSITE OR OFFERS REGARDLESS OF
                    WHETHER THE OFFER IS HOSTED BY US. WE MAKE AN EFFORT TO
                    SCREEN ALL OFFERS TO ENSURE THE BEST POSSIBLE EXPERIENCE FOR
                    OUR USERS. HOWEVER, WE ARE NOT RESPONSIBLE FOR DEALINGS
                    BETWEEN YOU AND A PARTNER. YOU ARE HOWEVER RESPONSIBLE FOR
                    AND MUST CAREFULLY REVIEW EACH PARTNER OFFER AND READ THEIR
                    TERMS AND CONDITION, AND THE PRIVACY POLICY.
                </p>

                <h3>11. Waiver of Claims and Indemnification</h3>
                <p>
                    By accessing or using our Sites or downloading Content, you
                    forever waive any and all claims you have or may have in the
                    future against us. You shall indemnify and hold us our
                    subsidiaries, officers, directors, attorneys employees and
                    licensors, harmless against any losses, claims, damages,
                    liabilities, penalties, actions, proceedings or judgments
                    (collectively, “Losses”) to which an Indemnified Party may
                    become subject that arise out of, or relate to: (i) your use
                    or inability to use the Content; (ii) any violation of this
                    Agreement or the license agreement or privacy policy
                    governing any Third Party Software; (iii) Your infringement
                    or misappropriation or alleged infringement or
                    misappropriation of any copyright, trademark, patent, trade
                    secret or other personal or proprietary right; or (iv) any
                    gross negligence or willful misconduct. You shall reimburse
                    us for all legal and other expenses, including without
                    limitation attorneys’ fees incurred in connection with
                    investigating, defending or settling any claim or loss.
                </p>

                <h3>12. Copyright Policy</h3>
                <p>
                    The Company respects the rights of copyright holders.
                    Pursuant to Title 17, United States Code, Section 512(c)(2),
                    notifications of claimed copyright infringement should be
                    sent to
                    <a href="mailto:webmaster@freshy.com"
                        >webmaster@freshy.com</a
                    >. To be effective, notifications must include the following
                    information: (i) a physical or electronic signature of a
                    person authorized to act on behalf of the owner of the
                    copyright that has been allegedly infringed; (ii)
                    identification of works or materials being infringed; (iii)
                    identification of the content that is claim to be infringing
                    including, information regarding that location of the
                    content that the copyright owner seeks to have removed, with
                    sufficient detail so that the Company is capable of finding
                    and verifying its existence; (iv) contact information about
                    the notifying party including address, telephone number and
                    email address; (v) a statement that the notifying party has
                    a good faith belief that the content is not authorized by
                    the copyright owner, its agent, or the law; and (vi) a
                    statement made under penalty of perjury that the information
                    provided is accurate and the notifying party is authorized
                    to make the complaint on behalf of the copyright owner. Once
                    a complete and proper notice of claimed copyright
                    infringement is received by the Company, it is the Company’s
                    policy to: (i) remove or disable access to the content on
                    the Company’s websites or content directories; and (ii)
                    block a user who has posted infringing content two or more
                    times from posting any further content.
                </p>

                <h3>13. Miscellaneous</h3>
                <strong>Enforcement, Choice of Law, Choice of Forum</strong>
                <p>
                    Every provision of this Agreement will be construed, to the
                    extent possible, so as to be valid and enforceable. If any
                    provision of this Agreement so construed is held invalid,
                    illegal or otherwise unenforceable, such provision will be
                    deemed severed from this Agreement, and all other provisions
                    will remain in full force and effect. This Agreement will in
                    all respects be governed by and interpreted, construed and
                    enforced in accordance with the laws of the United States of
                    America and the State of California without respect to its
                    choice of law provisions. Any action between the parties
                    will be venued in a California state or federal court. You
                    irrevocably submit to the personal jurisdiction in
                    California state or federal court.
                </p>

                <strong>No Modifications or Waiver</strong>
                <p>
                    This Agreement may not be modified or amended except by a
                    separate writing, signed by both you and us. The failure by
                    us at any time to enforce any of the provisions of this
                    Agreement or any right or remedy available here under or at
                    law or in equity, or to exercise any option herein provided,
                    will not constitute a waiver of such provision, right,
                    remedy or option or in any way affect the validity of this
                    Agreement. The waiver of any default by us will not be
                    deemed a continuing waiver, but will apply solely to the
                    instance to which such waiver is directed.
                </p>

                <strong>Effect of Agreement, Survival of Terms</strong>
                <p>
                    This Agreement will be binding upon and inure to the benefit
                    of the parties and their respective successors and permitted
                    assigns. No third party beneficiaries are intended or
                    created by virtue of this Agreement. This Agreement does not
                    create a partnership, joint venture or agency relationship
                    between the parties. Any terms of this Agreement that would,
                    by their nature, survive the expiration or termination of
                    this Agreement will survive. You acknowledge that you have
                    not been induced to enter into this Agreement by any
                    representations or statements, oral or written, not
                    expressly contained in this Agreement.
                </p>

                <strong>Electronic Form</strong>
                <p>
                    A printed version of this Agreement and of any notice given
                    in electronic form shall be admissible in judicial or
                    administrative proceeding to the same extent and subject to
                    the same conditions as other business documents and records
                    originally generated and maintained in printed form.
                </p>

                <strong>Correction of Errors and Inaccuracies</strong>
                <p>
                    This Agreement, the Content, and Sites may contain
                    typographical errors or other errors or inaccuracies. We
                    reserve the right to correct any errors, inaccuracies or
                    omissions and to change or update this Agreement, the
                    Content or Sites at any time without prior notice. We do not
                    however, guarantee that any errors, inaccuracies or
                    omissions will be corrected.
                </p>

                <h3>14. Questions or Additional Information</h3>
                <p>
                    If you have any questions regarding this Agreement or wish
                    to obtain additional information, you can contact us at
                    <a href="mailto:webmaster@freshy.com"
                        >webmaster@freshy.com</a
                    >.
                </p>

                <strong>Last Updated: February, 2020.</strong>
            </div>
            <div class="footer-page-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";

export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<style scoped src="./footerPage.css"></style>
