import * as firebaseUtil from "firebase/app";
import { User, USER_TYPE } from "../../auth/user";
import firebase, {
    googleProvider,
    facebookProvider,
    FIREBASE_AUTH_ERRORS,
    FIREBASE_AUTH_PROVIDERS,
    changeDisplayName,
} from "../../auth/firebase";

export const user = {
    state: {
        user: null,
        sendingVerification: false,
        lastVerificationTime: null,
    },
    getters: {
        recipeList: state => {
            if (state.user && state.user.recipeList)
                return state.user.recipeList;
            return null;
        },
    },
    mutations: {
        setUser(state, user) {
            if (user) {
                state.user = user;
            } else {
                // Emptying user out
                state.user = null;
            }
        },
        setSendingVerification(state, status) {
            state.sendingVerification = status;
        },
        setLastVerificationTime(state, timestamp) {
            state.lastVerificationTime = timestamp;
        },
    },
    actions: {
        async createUserWithEmail({ commit, state }, payload) {
            try {
                const {
                    email,
                    password,
                    firstName,
                    lastName,
                    accountType,
                    recipeList,
                } = payload;
                if (email && password && firstName && lastName) {
                    const req = await firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password);
                    if (req) {
                        // success  - commit token and set user
                        if (req.user.refreshToken) {
                            localStorage.setItem(
                                "access_token",
                                req.user.refreshToken
                            );
                        }

                        // Set user db info
                        const user = new User();
                        await user.initUser(
                            req.user,
                            {
                                firstName,
                                lastName,
                                accountType: accountType || USER_TYPE.free,
                                recipeList: recipeList || {},
                            },
                            true
                        );
                        await changeDisplayName(user.fullName);
                        commit("setUser", user);
                        return state.user;
                    }
                    return false;
                }
                return false;
            } catch (e) {
                if (e && e.code == FIREBASE_AUTH_ERRORS.emailAlreadyInUse) {
                    return { error: "user-already-exists" };
                }
                console.error("Problem creating user - ", e);
                return false;
            }
        },
        /**
         * Log in with google auth credential (most likely from one-tap sign in)
         * @param {*} credential
         * @returns {User} User that is logged in, else false
         */
        async loginWithGoogleCredential({ commit, state }, credential) {
            try {
                const firebaseCredential = googleProvider.credential(
                    credential
                );
                if (credential) {
                    const req = await firebase
                        .auth()
                        .signInWithCredential(firebaseCredential);
                    if (req && req.code != FIREBASE_AUTH_ERRORS.userNotFound) {
                        if (req.user.refreshToken) {
                            localStorage.setItem(
                                "access_token",
                                req.user.refreshToken
                            );
                        }
                        const user = new User();
                        if (
                            req.additionalUserInfo &&
                            req.additionalUserInfo.isNewUser &&
                            req.additionalUserInfo.profile
                        ) {
                            await user.initUser(
                                req.user,
                                {
                                    firstName:
                                        req.additionalUserInfo.profile
                                            .given_name,
                                    lastName:
                                        req.additionalUserInfo.profile
                                            .family_name,
                                    accountType: USER_TYPE.free,
                                    recipeList: {},
                                },
                                true
                            );
                            await changeDisplayName(user.fullName);
                        } else await user.initUser(req.user);
                        commit("setUser", user);
                        return state.user;
                    }
                    return false;
                }
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        /**
         * Log in with token
         * @param {String} token token to use to login
         */
        async loginWithToken({ commit, state }, token) {
            try {
                if (token) {
                    const req = await firebase
                        .auth()
                        .signInWithCustomToken(token);
                    if (req && req.code != FIREBASE_AUTH_ERRORS.userNotFound) {
                        if (req.user.refreshToken) {
                            localStorage.setItem(
                                "access_token",
                                req.user.refreshToken
                            );
                        }

                        const user = new User();
                        await user.initUser(req.user);
                        commit("setUser", user);
                        return state.user;
                    }
                    return false;
                }
                return false;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        /**
         * Login with email, include object {email:<>, password:<>} in payload
         * @param {Object} payload - type {email:<address>, password:<password>}
         * @returns {User} user class object of logged in user, or false if failed to log in
         */
        async loginWithEmail({ commit, state }, payload) {
            const { email, password } = payload;
            try {
                if (!email || !password) {
                    return false;
                }
                const req = await firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password);

                if (req && !req.code) {
                    // success  - commit token and set user
                    if (req.user.refreshToken) {
                        localStorage.setItem(
                            "access_token",
                            req.user.refreshToken
                        );
                    }

                    const user = new User();
                    await user.initUser(req.user);
                    commit("setUser", user);
                    return state.user;
                }
                return false;
            } catch (e) {
                if (e && e.code) {
                    console.error("ERROR", e);
                    return { error: e.code };
                }
                console.error("Problem authenticating with email - ", e);
                return false;
            }
        },
        /**
         * Starts google pop signin flow
         * @returns current user
         */
        async loginWithGoogle({ commit, state }) {
            try {
                const provider = googleProvider;

                provider.addScope("profile");
                provider.addScope("email");
                const req = await firebase.auth().signInWithPopup(provider);
                if (req && req.code != FIREBASE_AUTH_ERRORS.userNotFound) {
                    if (req.user.refreshToken) {
                        localStorage.setItem(
                            "access_token",
                            req.user.refreshToken
                        );
                    }
                    const user = new User();
                    if (
                        req.additionalUserInfo &&
                        req.additionalUserInfo.isNewUser &&
                        req.additionalUserInfo.profile
                    ) {
                        await user.initUser(
                            req.user,
                            {
                                firstName:
                                    req.additionalUserInfo.profile.given_name,
                                lastName:
                                    req.additionalUserInfo.profile.family_name,
                                accountType: USER_TYPE.free,
                                recipeList: {},
                                emailVerified: true, // Google - we automatically set email verification to true
                            },
                            true
                        );
                        await changeDisplayName(user.fullName);
                    } else await user.initUser(req.user);
                    commit("setUser", user);
                    return state.user;
                }
            } catch (e) {
                console.error("Problem authenticating with google - ", e);
                return false;
            }
        },
        /**
         * Starts facebook pop signin flow
         * @returns current user
         */
        async loginWithFacebook({ commit, state }, payload = {}) {
            try {
                const { accountType, recipeList } = payload;

                const provider = facebookProvider;
                provider.addScope("public_profile");
                const req = await firebase.auth().signInWithPopup(provider);
                if (req && req.code != FIREBASE_AUTH_ERRORS.userNotFound) {
                    if (req.user.refreshToken) {
                        localStorage.setItem(
                            "access_token",
                            req.user.refreshToken
                        );
                    }
                    const user = new User();
                    if (
                        req.additionalUserInfo &&
                        req.additionalUserInfo.isNewUser &&
                        req.additionalUserInfo.profile
                    ) {
                        await user.initUser(
                            req.user,
                            {
                                firstName:
                                    req.additionalUserInfo.profile.first_name,
                                lastName:
                                    req.additionalUserInfo.profile.last_name,
                                accountType: accountType || USER_TYPE.free,
                                recipeList: recipeList || {},
                                emailVerified: true, // Facebook - we automatically set email verification to true
                            },
                            true
                        );
                        await changeDisplayName(user.fullName);
                    } else await user.initUser(req.user);
                    commit("setUser", user);
                    return state.user;
                }
            } catch (e) {
                console.error("Problem authenticating with google - ", e);
                return false;
            }
        },
        /**
         * Reauthenticate a user
         *
         * @param {*} payload should contain provider (email google or facebook) and if Email also email and password
         */
        async reauthenticate({ commit }, payload) {
            try {
                const { provider, email, password } = payload;
                if (provider && provider != FIREBASE_AUTH_PROVIDERS.email) {
                    // Popup provider
                    let reauthProvider = null;
                    if (provider == FIREBASE_AUTH_PROVIDERS.google) {
                        reauthProvider = googleProvider;
                    } else if (provider == FIREBASE_AUTH_PROVIDERS.facebook) {
                        reauthProvider = facebookProvider;
                    }

                    if (reauthProvider) {
                        const req = await firebase
                            .auth()
                            .currentUser.reauthenticateWithPopup(
                                reauthProvider
                            );
                        if (req) {
                            const user = new User();
                            await user.initUser(req.user);
                            commit("setUser", user);
                            return true;
                        }
                    } else return null;
                } else if (
                    provider &&
                    provider == FIREBASE_AUTH_PROVIDERS.email &&
                    email &&
                    password
                ) {
                    // Email/Pass provider
                    const credentials = await firebaseUtil.default.auth.EmailAuthProvider.credential(
                        email,
                        password
                    );
                    const req = await firebase
                        .auth()
                        .currentUser.reauthenticateWithCredential(credentials);
                    if (req) {
                        const user = new User();
                        await user.initUser(req.user);
                        commit("setUser", user);
                        return true;
                    }
                } else return null;
            } catch (e) {
                if (e && e.code) {
                    console.error("ERROR", e);
                    return { error: e.code };
                }
                console.error(
                    "Problem reauthorizing with provider -",
                    payload,
                    ": ",
                    e
                );
                return false;
            }
        },
        /**
         * Checks for and refreshes the current firebase user, and if one exists sets it back in store.
         */
        async reloadUser({ commit }) {
            try {
                if (firebase.auth().currentUser)
                    await firebase.auth().currentUser.reload();
                const req = firebase.auth().currentUser;
                if (req) {
                    const user = new User();
                    await user.initUser(req);
                    commit("setUser", user);
                }
            } catch (e) {
                console.error("Error with firebase - ", e);
            }
        },
        /**
         * Logout function
         * @returns {string} returns previous user email only.
         */
        async logout({ commit, state }) {
            try {
                if (!state.user) return false;

                const oldUser = state.user;
                await firebase.auth().signOut();
                localStorage.removeItem("access_token");
                localStorage.removeItem("displayName");
                commit("setUser", null);
                return oldUser.email;
            } catch (e) {
                commit("setUser", null);
                return false;
            }
        },
        /**
         * Sends a verification email to current user
         * @returns object with success property. True is successful, false if not with reason
         */
        async sendEmailVerification({ state, commit }) {
            try {
                const minimumTimespan = 180000;
                const lastSent = state.lastVerificationTime;
                const isNotTooSoon = Date.now() - minimumTimespan > lastSent;
                const isSending = state.sendingVerification;
                if (firebase.auth().currentUser && isNotTooSoon && !isSending) {
                    commit("setSendingVerification", true);
                    commit("setLastVerificationTime", Date.now());
                    await firebase.auth().currentUser.sendEmailVerification();
                    commit("setSendingVerification", false);
                    return { success: true };
                }
                if (!isNotTooSoon) {
                    return { success: false, reason: "too_soon" };
                }
                if (isSending)
                    return { success: false, reason: "currently_sending" };
                return { success: false, reason: "unknown" };
            } catch (e) {
                if (e && e.code == FIREBASE_AUTH_ERRORS.tooManyRequests) {
                    return { success: false, reason: "too_many_requests" };
                }
                console.error("Error trying to send email Verification", e);
                return { success: false, reason: "unknown", data: e };
            }
        },
        async deleteUser({ dispatch, state }) {
            try {
                const { currentUser } = firebase.auth();
                if (currentUser && state.user) {
                    // Wait for these three individually
                    await state.user.deleteUserDBObj();
                    await currentUser.delete();
                    await dispatch("logout");
                    return true;
                }
                return false;
            } catch (e) {
                console.error("Error deleting user", e);
                return false;
            }
        },
    },
};
