<template>
    <div id="article-list" class="article-list-section">
        <h2>Latest Eats Ideas</h2>

        <div id="blog-article-list" v-if="ideas.length">
            <div class="p-mb-4" v-for="idea in ideas" :key="idea.id">
                <a class="blog-card-link" :href="idea.link">
                    <div class="blog-card-left">
                        <img class="blog-card-img" :src="getIdeaImage(idea)" />
                    </div>
                    <div class="blog-card-right">
                        <div class="blog-card-data">
                            <div class="blog-card-meta-head">
                                <span class="blog-card-tag">{{
                                    idea?.category_names?.shift()
                                }}</span>
                                <span class="blog-card-date">{{
                                    formattedPublishedDate(idea)
                                }}</span>
                            </div>
                            <div>
                                <span
                                    class="blog-card-title"
                                    title="article title"
                                    >{{ idea.title?.rendered }}</span
                                >
                            </div>

                            <div class="blog-author">
                                Written By
                                <span class="author-tag">{{
                                    idea?.article_author
                                }}</span>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { getLatestIdeas } from "../fetchFunctions";

export default {
    props: ["image", "title", "uuid", "recipe"],
    data() {
        return {
            ideas: [],
        };
    },
    computed: {},
    async created() {
        const latestIdeas = await getLatestIdeas({
            count: 4,
            embed: true,
            // don't get posts w/ category "Top Feature, Featured"
            params: { categories_exclude: "15,16" },
        });
        if (latestIdeas && latestIdeas.length > 0) {
            this.ideas = latestIdeas;
        }
    },
    methods: {
        getIdeaImage(idea) {
            const featMedia = idea?._embedded?.["wp:featuredmedia"];
            return featMedia?.length && featMedia[0].source_url;
        },
        formattedPublishedDate(idea) {
            return new Date(idea && `${idea.date}Z`).toLocaleDateString(
                "default",
                {
                    month: "long",
                    day: "numeric",
                }
            );
        },
    },
};
</script>

<style scoped>
#article-list h2 {
    font-family: "Roboto Slab", serif;
    font-size: 1.75rem;
    padding-bottom: 0.5rem;
    margin: 0px;
}

.blog-card-link {
    text-decoration: none;
    color: #3f3f3f;
    margin-bottom: 1rem;
}

.blog-card-title {
    font-family: "Roboto Slab", serif;
    font-size: 1.25rem;
}

.blog-card-right {
    padding: 0.5rem 0;
}

.blog-card-tag {
    font-weight: bold;
    color: white;
    background: #ffc000;
    padding: 0.1rem 0.25rem;
}

.blog-card-title {
    color: #0557e7;
}

.blog-card-meta-head span {
    margin-right: 0.25rem;
}

.blog-card-date,
.blog-author {
    opacity: 0.7;
}

.blog-author {
    padding-bottom: 0.5rem;
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #c1c1c1;
}
</style>
