import { mapActions } from "vuex";

export default {
    data() {
        return {
            onSuccess: null,
        };
    },
    methods: {
        ...mapActions(["sendEmailVerification"]),
        async sendVerification() {
            try {
                const res = await this.sendEmailVerification();
                if (res && res.success) {
                    this.$toast.add({
                        severity: "success",
                        summary: "Verification Email Sent",
                        detail:
                            "Check your email for the verification link. (Don't forget to check your spam folder)",
                        life: 5000,
                    });
                } else if (res && !res.success) {
                    if (res.reason == "too_soon") {
                        this.$toast.add({
                            severity: "error",
                            summary: "Just sent",
                            detail:
                                "Please wait 3 minutes before sending another verification email.",
                            life: 5000,
                        });
                    } else if (res.reason == "too_soon") {
                        this.$toast.add({
                            severity: "error",
                            summary: "Currently Sending",
                            detail:
                                "Already started sending a verification email",
                            life: 5000,
                        });
                    } else if (res.reason == "too_many_requests") {
                        this.$toast.add({
                            severity: "error",
                            summary: "Too Many Attempts",
                            detail:
                                "Maximum Attempts reached. Please wait and try again later",
                            life: 5000,
                        });
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Email Not Sent",
                            detail:
                                "Unknown error. Please refer to our contact page to alert us to your problem.",
                            life: 5000,
                        });
                    }
                }
            } catch (e) {
                console.error("Error with send Verification mixin function", e);
            }
        },
        onSuccessfulSend() {
            if (this.onSuccess) {
                this.$emit("emailVerifySent", true);
            }
        },
    },
};
