<template>
    <div class="recipeCardRoot">
        <div class="recipe-card-wrapper p-grid">
            <div class="recipe-card-left p-col-6">
                <Skeleton width="100%" height="100%" animation="wave" />
            </div>
            <div class="recipe-card-right p-col-6">
                <div class="recipe-card-data">
                    <div
                        class="recipe-card-title recipe-card-data"
                        style="padding-top: 2px;"
                    >
                        <Skeleton height="80%" animation="wave" />
                    </div>
                    <div class="recipe-card-data">
                        <Skeleton animation="wave" />
                    </div>

                    <div class="recipe-card-calories recipe-card-data">
                        <Skeleton animation="wave" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Skeleton from "primevue/skeleton";

export default {
    name: "recipe-card-skelton",
    components: {
        Skeleton,
    },
    data() {
        return {};
    },
};
</script>

<style scoped src="./recipeCard.css"></style>
