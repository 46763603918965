export default {
    data() {
        return {};
    },
    computed: {
        isNewtab() {
            return this.$route.name.toLowerCase() == "newtab";
        },
    },
};
