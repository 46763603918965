<template>
    <div v-if="recipes && isValid" class="p-d-print-none">
        <Carousel
            :value="recipes"
            :circular="false"
            :numVisible="getCarouselVisible"
            :responsiveOptions="getCarouselOptions"
        >
            <template #header>
                <div class="home-header-title hero-carousel-header-title">
                    <h2>Recommended Recipes</h2>
                    <h3>{{ subheader }}</h3>
                </div>
            </template>
            <template #item="recipe">
                <feed-card
                    v-if="
                        recipe.data &&
                            recipe.data.dataReturnType &&
                            recipe.data.dataReturnType == 'yfeed'
                    "
                    :feedData="recipe.data"
                />
                <recipe-card
                    v-else
                    :recipe="recipe.data"
                    :title="recipe.data.resultEntity"
                    :image="recipe.data.resultImage"
                    :calories="recipe.data.resultCalories"
                    :carbs="recipe.data.resultCarbs"
                    :cooktime="
                        recipe.data.recipedata &&
                            recipe.data.recipedata.total_time
                    "
                    :url="recipe.data.url"
                    @onClick="onRecipeClick"
                />
            </template>
        </Carousel>
    </div>
</template>

<script>
import Carousel from "primevue/carousel";
import recipeMixin from "../componentMixins/recipeMixin";
import userMixin from "../../store/mixins/user";
import { getRecommendations } from "../../fetchFunctions";
import RecipeCard from "../recipeCards/RecipeCard";
import FeedCard from "../recipeCards/FeedCard.vue";
import { shuffleArr, insertInArrAtInterval } from "../../utility";

export default {
    name: "recipe-recommendations",
    mixins: [recipeMixin, userMixin],
    props: ["seedRecipes", "subheader"],
    components: {
        Carousel,
        RecipeCard,
        FeedCard,
    },
    data() {
        return {
            recipes: null,
            isValid: false,
        };
    },
    async created() {
        try {
            this.recipes = await getRecommendations(this.seedRecipes);
            if (
                this.recipes &&
                this.recipes.length > 0 &&
                this.$store.state.yfeeds.yFeed &&
                this.$store.state.yfeeds.yFeed.data &&
                this.$store.state.yfeeds.yFeed.data.data
            ) {
                const feedItems = shuffleArr(
                    this.$store.state.yfeeds.yFeed.data.data
                );
                const firstItem = feedItems.shift();
                this.recipes = [
                    firstItem,
                    ...insertInArrAtInterval(this.recipes, feedItems),
                ];
                this.isValid = true;
            }
        } catch (e) {
            console.error("Error getting recommendations");
        }
    },
    computed: {
        getCarouselVisible() {
            return Math.min(4, this.recipes.length);
        },
        getCarouselOptions() {
            return [
                {
                    breakpoint: "1200px",
                    numVisible: Math.min(4, this.recipes.length),
                    numScroll: 1,
                },
                {
                    breakpoint: "1000px",
                    numVisible: Math.min(4, this.recipes.length),
                    numScroll: 1,
                },
                {
                    breakpoint: "600px",
                    numVisible: 1,
                    numScroll: 1,
                },
            ];
        },
    },
    methods: {
        onRecipeClick(data) {
            this.$router.push({
                name: "Recipe",
                params: { uuid: data.uuid || data.data.uuid },
            });
        },
    },
};
</script>

<style src="./recommendationCarousel.css"></style>
