<template>
    <div id="search-root">
        <OverlayAuthWindow
            v-if="showLogin"
            @closed="hideDialog"
        ></OverlayAuthWindow>
        <div id="search-header">
            <Header :triggerOnEmit="true" @onSearch="onSearch" />
        </div>
        <div id="search-body">
            <div class="outer-wrapper">
                <div
                    v-if="totalResultsCount && enteredQuery"
                    id="resultsReturn"
                >
                    {{ totalResultsCount }} Results for
                    <span>{{ enteredQuery }}</span>
                </div>
                <div
                    v-if="adRecords"
                    id="recipes-special-footer"
                    class="recipesSpecialFooter"
                >
                    <div class="adsfooterlabel">
                        Ads Related to
                        <strong> {{ enteredQuery }} </strong>
                    </div>
                    <div
                        id="recipes-special-inner-wrapper"
                        class="recipesSpecialInnerWrapper"
                    >
                        <div
                            v-for="(record, index) in adRecords"
                            :key="index"
                            :id="`recipes=special-record_${index}`"
                            class="recipesSpecialRecord test"
                            :data-yiid="record.data_yiid"
                            :data-appns="record.data_k && record.data_appNs"
                            :data-k="record.data_appNs && record.data_k"
                            :data-admd="record.data_admd"
                            @click="sendXMLTracking(2)"
                        >
                            <a
                                :id="
                                    `recipes-special-results-primary-area_${index}`
                                "
                                class="recipesSpecialResultsPrimaryArea"
                                target="_blank"
                                :href="record.url"
                            >
                                <div
                                    :id="
                                        `recipes-special-record-title_${index}`
                                    "
                                    class="recipesSpecialRecordTitle"
                                >
                                    <span v-html="record.title"></span>
                                </div>
                                <div
                                    :id="`recipes-special-record-link_${index}`"
                                    class="recipesSpecialRecordLink"
                                >
                                    {{ record.display_url }}
                                </div>
                                <div
                                    :id="
                                        `recipes-special-record-description_${index}`
                                    "
                                    class="recipesSpecialRecordDescription"
                                >
                                    <span v-html="record.description"></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div id="searchResults">
                    <div v-if="loading" id="search-loading">
                        <div
                            class="serp-recipe-card-wrapper"
                            v-for="index in 7 - getPerLineMax()"
                            :key="index"
                        >
                            <div class="serp-recipe-row">
                                <recipe-card-skeleton
                                    v-for="i in getPerLineMax()"
                                    :key="i"
                                />
                            </div>
                            <div
                                v-if="doShowAd(index, 8 - getPerLineMax())"
                                class="sponsor-wrapper"
                            >
                                <div
                                    class="sponsor-inner-wrapper"
                                    style="border: none !important;"
                                >
                                    <Skeleton height="100%" width="100%" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else-if="!loading && searchComplete"
                        id="search-complete"
                    >
                        <div
                            v-if="
                                !formattedRecipesSets ||
                                    formattedRecipesSets.length == 0
                            "
                        >
                            <div id="search-not-found-body-wrapper">
                                <h1 class="page-title">
                                    No Results for {{ enteredQuery }}
                                </h1>
                                <p>
                                    Check the spelling, try a more general
                                    recipe term like "dinner", "chicken", or
                                    "cookies".
                                </p>
                            </div>
                        </div>
                        <div
                            v-else-if="
                                formattedRecipesSets &&
                                    formattedRecipesSets.length > 0
                            "
                        >
                            <!-- are records after searching -->
                            <div
                                class="serp-recipe-card-wrapper"
                                v-for="(recipeList,
                                index) in formattedRecipesSets"
                                :key="index"
                                @click="sendXMLTracking(3)"
                            >
                                <div class="serp-recipe-row">
                                    <span
                                        class="serp-recipe-card"
                                        v-for="(recipe, i) in recipeList"
                                        :key="i"
                                    >
                                        <feed-card
                                            v-if="
                                                recipe &&
                                                    recipe.dataReturnType &&
                                                    recipe.dataReturnType ==
                                                        'yfeed'
                                            "
                                            :feedData="recipe"
                                        />
                                        <a
                                            v-if="
                                                !recipe.dataReturnType ||
                                                    recipe.dataReturnType !=
                                                        'yfeed'
                                            "
                                            :href="`/recipe/${recipe.uuid}`"
                                        >
                                            <recipe-card
                                                :recipe="recipe"
                                                :url="recipe.url"
                                                :title="recipe.resultEntity"
                                                :image="
                                                    recipe.resultImageAsset
                                                        ? recipe.resultImageAsset
                                                        : recipe.recipedata &&
                                                          recipe.recipedata
                                                              .image
                                                        ? getImageWrapper(
                                                              recipe.recipedata
                                                                  .image
                                                          )
                                                        : ''
                                                "
                                                :calories="
                                                    recipe.resultCalories
                                                "
                                                :carbs="recipe.resultCarbs"
                                                :cooktime="
                                                    getIsoDurationInMinutes(
                                                        recipe.resultTotalTime
                                                    )
                                                "
                                                @needLogin="onNeedLogin"
                                            />
                                        </a>
                                    </span>
                                </div>
                                <div
                                    v-if="
                                        doShowAd(
                                            index,
                                            formattedRecipesSets.length
                                        )
                                    "
                                    class="sponsor-wrapper"
                                >
                                    <div class="adsense-wrapper">
                                        <div class="adsense adsense72890">
                                            728 x 90 ad
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="hasSearched" class="serp-paginator-wrapper">
                        <paginator
                            :rows="12"
                            :pageLinkSize="8"
                            :totalRecords="totalResultsCount"
                            @page="onPageChange($event)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div id="search-footer">
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Paginator from "primevue/paginator";
import Skeleton from "primevue/skeleton";
import Header from "../../components/header/Header.vue";
import Footer from "../../components/footer/Footer.vue";
import RecipeCard from "../../components/recipeCards/RecipeCard.vue";
import FeedCard from "../../components/recipeCards/FeedCard.vue";
import RecipeCardSkeleton from "../../components/recipeCards/RecipeCardSkeleton.vue";
import OverlayAuthWindow from "../../components/auth/authWindow/OverlayAuthWindow";
import { recipeSearch, getYahooAds } from "../../fetchFunctions";
import {
    getMediaBreakpoint,
    getIsoDurationInSeconds,
    insertInArrAtInterval,
    shuffleArr,
    getImageWrapper,
} from "../../utility";
import userMixin from "../../store/mixins/user";
import { yahooXMLClickEvent } from "../../analytics";

export default {
    name: "Search",
    props: ["q"],
    mixins: [userMixin],
    components: {
        Header,
        Footer,
        RecipeCard,
        FeedCard,
        RecipeCardSkeleton,
        Paginator,
        Skeleton,
        OverlayAuthWindow,
    },
    data() {
        return {
            query: null,
            enteredQuery: null,
            loading: false,
            searchComplete: false,
            serpRecipes: [],
            formattedRecipesSets: [],
            totalResultsCount: 0,
            totalPages: 0,
            numPerPage: 0,
            currentPage: 0,
            maxPerLine: 4,
            hasSearched: false,
            currData: null,
            showDetails: false,
            detailsKey: 0,
            showLogin: false,
            adRecords: null,
            fullSearchResponse: null,
        };
    },
    mounted() {
        this.onPageLoad();

        // listeners
        window.addEventListener("resize", () => {
            this.maxPerLine = this.getPerLineMax();
            this.formatRecipesSets(this.maxPerLine);
        });
    },
    methods: {
        sendXMLTracking(type) {
            yahooXMLClickEvent(type, this.fullSearchResponse);
            this.$trTracking.fireEvent({
                action: "click",
                option: this.$route.name,
                feed: "yahoo xml",
            });
        },
        onPageLoad() {
            const qs = new URLSearchParams(window.location.search);
            if (qs && qs.get("q")) this.runSearch(qs.get("q"));
            else window.location.href = "/cooking/";
        },
        onSearch(query) {
            this.$router.push({
                name: "Search",
                query: {
                    q: query,
                },
            });
        },
        getImageWrapper(url) {
            return getImageWrapper(url);
        },
        getIsoDurationInMinutes(iso) {
            if (!iso) return null;

            const seconds = getIsoDurationInSeconds(iso);
            if (seconds && seconds > 0) return Math.ceil(seconds / 60);
            return null;
        },
        async runSearch(query, options = {}, resetCounts = true) {
            if (!this.loading) {
                this.query = query;
                this.enteredQuery = query;
                if (!options.perPage) {
                    if (this.$store.state.yfeeds.yFeed) options.perPage = 8;
                    else options.perPage = 11;
                }

                this.loading = true;
                this.searchComplete = false;
                this.serpRecipes = [];
                this.adRecords = null;
                this.fullSearchResponse = null;
                if (resetCounts) {
                    this.totalResultsCount = 0;
                    this.totalPages = 0;
                    this.numPerPage = 0;
                    this.currentPage = 0;
                    this.hasSearched = false;
                }

                const { typeTag } = this.$store.state.attribution;

                this.fullSearchResponse = await getYahooAds(query, typeTag);
                const yahooAdsRes =
                    this.fullSearchResponse && this.fullSearchResponse.ads;
                const res = await recipeSearch(query, typeTag, options);

                if (
                    res &&
                    res.total > 0 &&
                    res.records &&
                    res.records.length > 0
                ) {
                    this.serpRecipes = res.records;

                    if (
                        this.$store.state.yfeeds.yFeed &&
                        this.$store.state.yfeeds.yFeed.data &&
                        this.$store.state.yfeeds.yFeed.data.data &&
                        this.$store.state.yfeeds.yFeed.data.data.length
                    ) {
                        const feedItems = shuffleArr(
                            this.$store.state.yfeeds.yFeed.data.data
                        );

                        const firstItem = feedItems.shift();
                        this.serpRecipes.unshift(firstItem);
                        const recipeTemp = insertInArrAtInterval(
                            this.serpRecipes,
                            feedItems,
                            5,
                            3
                        );

                        this.serpRecipes = recipeTemp;
                    }

                    this.enteredQuery = this.query;
                    this.pushChangedQueryParam(this.enteredQuery);
                    this.formatRecipesSets(this.getPerLineMax());

                    const res2 = yahooAdsRes;
                    if (res2) {
                        if (res2.length > 4) this.adRecords = res2.slice(0, 4);
                        else this.adRecords = res2;

                        this.$trTracking.fireEvent({
                            action: "impression",
                            option: this.$route.name,
                            feed: "yahoo xml",
                            ads: this.adRecords.length,
                        });
                    }

                    if (resetCounts) {
                        this.totalPages = res.numPages;
                        this.numPerPage = res.recordsPerPage;
                        this.currentPage = res.currentPage;
                        this.totalResultsCount = res.total;
                        this.hasSearched = true;
                    }

                    window.scrollTo(0, 0);
                }

                this.loading = false;
                this.searchComplete = true;
            }
        },
        pushChangedQueryParam(newParam) {
            const url = new URL(window.location.href);
            url.searchParams.set("q", newParam);
            window.history.pushState(
                {},
                `Cooking.freshy.com - Search: ${this.enteredQuery}`,
                url.toString()
            );
        },
        onPageChange(ev) {
            window.scrollTo(0, 0);
            const { page } = ev;
            this.runSearch(
                this.query,
                {
                    pageNo: page,
                },
                false
            );
        },
        formatRecipesSets(maxPerLine) {
            if (this.serpRecipes && this.serpRecipes.length > 0) {
                const r = JSON.parse(JSON.stringify(this.serpRecipes));

                const arr = new Array(Math.ceil(r.length / maxPerLine)).fill();
                for (let i = 0; i < arr.length; i++) {
                    arr[i] = r.splice(0, maxPerLine);
                }
                this.formattedRecipesSets = arr;
            } else return null;
        },
        getPerLineMax() {
            let perSet = 4;

            const size = getMediaBreakpoint();
            if (size) {
                if (size == "lg") perSet = 3;
                else if (size == "md") perSet = 2;
                else if (size != "xl") perSet = 1;
            }
            return perSet;
        },
        doShowAd(index, lineCount) {
            // Don't show on last
            if (index >= lineCount - 1) return false;

            if (this.getPerLineMax() == 1) {
                // Show every three results
                if (index > 0 && (index + 1) % 3 == 0) return true;
                return false;
            }
            return true;
        },
        onNeedLogin() {
            this.showLogin = true;
        },
        hideDialog() {
            this.showLogin = false;
        },
    },
};
</script>

<style scoped src="./search.css">
/* do not use import */
</style>
