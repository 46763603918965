import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";
import { store } from "../store/store";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};
export default !firebase.apps.length
    ? firebase.initializeApp(firebaseConfig)
    : firebase.app();

const database = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const emailProvider = new firebase.auth.EmailAuthProvider();

async function checkIfEmailExists(email, fullData = false) {
    try {
        if (email && email != "") {
            const req = await firebase.auth().fetchSignInMethodsForEmail(email);
            let check = null;
            if (Array.isArray(req) && req.length == 0) check = false;
            else if (Array.isArray(req) && req.length > 0) check = true;

            if (!fullData) return check;
            return { check, data: req };
        }
    } catch (e) {
        console.error("Error verifying email account providers - ", e);
        return null;
    }
}

async function sendResetPasswordEmail(email) {
    try {
        await firebase.auth().sendPasswordResetEmail(email);
    } catch (e) {
        console.error("Error sending Reset Password Email - ", e);
        return false;
    }
}

async function checkCode(code) {
    try {
        const req = await firebase.auth().checkActionCode(code);
        return { success: true, data: req };
    } catch (e) {
        console.error("Error Verifying Auth Code - ", e);
        return { success: false, data: e };
    }
}

async function applyCode(code) {
    try {
        const req = await firebase.auth().applyActionCode(code);
        return { success: true, data: req };
    } catch (e) {
        console.error("Error Applying Auth Code - ", e);
        return { success: false, data: e };
    }
}

async function checkPasswordResetCode(code) {
    try {
        const req = await firebase.auth().verifyPasswordResetCode(code);
        return { success: true, data: req };
    } catch (e) {
        console.error("Error Checking Password Reset Code - ", e);
        return { success: false, data: e };
    }
}

async function confirmPasswordReset(code, password) {
    try {
        const req = await firebase.auth().confirmPasswordReset(code, password);
        return { success: true, data: req };
    } catch (e) {
        console.error("Error Applying Password Reset Code - ", e);
        return { success: false, data: e };
    }
}

async function changeDisplayName(name) {
    try {
        const user = firebase.auth().currentUser;
        if (!user || !name) return null;

        await user.updateProfile({
            displayName: name,
        });
        return true;
    } catch (e) {
        console.error("Error setting Display Name", e);
        return null;
    }
}

const FIREBASE_AUTH_PROVIDERS = {
    google: "google",
    facebook: "facebook",
    email: "email",
};

const FIREBASE_AUTH_PROVIDER_IDS = {
    "google.com": FIREBASE_AUTH_PROVIDERS.google,
    "facebook.com": FIREBASE_AUTH_PROVIDERS.facebook,
    password: FIREBASE_AUTH_PROVIDERS.email,
};

function getReauthenticationProvider() {
    try {
        if (firebase.auth().currentUser.providerData[0].providerId) {
            if (
                FIREBASE_AUTH_PROVIDER_IDS[
                    firebase.auth().currentUser.providerData[0].providerId
                ]
            )
                return FIREBASE_AUTH_PROVIDER_IDS[
                    firebase.auth().currentUser.providerData[0].providerId
                ];
        } else return null;
    } catch (e) {
        console.error("Problem getting account type", e);
        return null;
    }
}

const FIREBASE_AUTH_ERRORS = {
    wrongPassword: "auth/wrong-password",
    weakPassword: "auth/weak-password",
    emailAlreadyInUse: "auth/email-already-in-use",
    userNotFound: "auth/user-not-found",
    userDisabled: "auth/user-disabled",
    invalidEmail: "auth/invalid-email",
    tooManyRequests: "auth/too-many-requests",
    userMismatch: "auth/user-mismatch",
    invalidActionCode: "auth/invalid-action-code",
    expiredActionCode: "auth/expired-action-code",
};

async function changeUserPassword(newPassword) {
    try {
        if (firebase.auth().currentUser) {
            await firebase.auth().currentUser.updatePassword(newPassword);
            return true;
        }
        return false;
    } catch (e) {
        console.error("Error trying to change password", e);
        return false;
    }
}

export {
    googleProvider,
    facebookProvider,
    emailProvider,
    database,
    FIREBASE_AUTH_PROVIDERS,
    FIREBASE_AUTH_PROVIDER_IDS,
    FIREBASE_AUTH_ERRORS,
    checkIfEmailExists,
    sendResetPasswordEmail,
    confirmPasswordReset,
    checkCode,
    applyCode,
    getReauthenticationProvider,
    changeUserPassword,
    checkPasswordResetCode,
    changeDisplayName,
};

if (window.google) {
    window.google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        // auto_select: true,
        callback: googleUser => {
            store.dispatch("loginWithGoogleCredential", googleUser.credential);
        },
    });
}

firebase.auth().useDeviceLanguage();
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
