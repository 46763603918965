import { createStore } from "vuex";
import { user } from "./modules/user";
import { yfeeds } from "./modules/yfeeds";

export const store = createStore({
    modules: {
        user,
        yfeeds,
    },
});
