<template>
    <div id="uninstall-root" class="footerPageRoot">
        <div class="footer-page-outer-wrapper">
            <div class="footer-page-header">
                <Header />
            </div>
            <div class="footer-page-body outer-wrapper page-body">
                <h1 class="page-title">How to Uninstall</h1>
                <ul>
                    <a href="#chrome"><li>Google Chrome</li></a>
                    <a href="#safari"><li>Apple Safari (macOS)</li></a>
                    <a href="#firefox"><li>Mozilla Firefox</li></a>
                    <a href="#opera"><li>Opera</li></a>
                    <a href="#edge"><li>Microsoft Edge</li></a>
                    <a href="#other"><li>Other Desktop Browsers</li></a>
                </ul>
                <div class="spacer"></div>
                <h2>Removal Instructions For Browser Extensions</h2>
                <p>
                    Our web browser extensions or add-ons have passed a review
                    process and are digitally signed by companies that make
                    popular web browsers. They can only be installed with the
                    explicit permission of the browser user because we make an
                    effort to follow the extension policies of browser makers.
                    You can easily disable or completely remove or uninstall
                    them at any time by using your browser’s extensions manager,
                    which is typically part of your browser’s settings, options
                    or preferences. Here are directions for some popular desktop
                    browsers:
                </p>

                <h3 id="chrome">Google Chrome</h3>
                <ol>
                    <li>
                        In Chrome’s Omnibox (a.k.a. address bar), enter
                        <strong>chrome://extensions</strong> and then press the
                        Enter (PC) or Return (Mac) key to open Chrome’s
                        Extensions manager.
                    </li>
                    <li>
                        Click the Remove button for an extension to uninstall
                        it, or toggle its switch to turn off the extension
                        without removing it.
                    </li>
                </ol>
                <p>
                    Our extensions cannot work after they have been turned off
                    or removed. If you are still experiencing undesired behavior
                    in Chrome, try following Google’s instructions to
                    <a
                        href="//support.google.com/chrome/answer/3296214"
                        target="_blank"
                        >Reset Chrome settings to default.</a
                    >
                </p>

                <h3 id="safari">Apple Safari (macOS)</h3>
                <ol>
                    <li>
                        In the Safari menu, choose Preferences, and then choose
                        the Extensions toolbar icon. (Alternatively, you can
                        open Safari’s Preferences window by using the keyboard
                        shortcut, Command–Comma.)
                    </li>
                    <li>
                        Deselect the checkbox for an extension to turn it off,
                        or select it and choose its Uninstall button to remove
                        it.
                    </li>
                </ol>
                <p>
                    Our extensions cannot work after they have been turned off
                    or uninstalled. If you are still experiencing undesired
                    behavior in Safari, try following Apple’s recommendations,
                    <a href="//support.apple.com/en-us/HT203353" target="_blank"
                        >If Safari is slow, stops responding, quits
                        unexpectedly, or has other issues.</a
                    >
                </p>

                <h3 id="firefox">Mozilla Firefox</h3>
                <ol>
                    <li>
                        In Firefox’s QuantumBar (a.k.a. address bar), enter
                        <strong>about:addons</strong> and then press the Enter
                        (PC) or Return (Mac) key to open Firefox’s Extensions
                        (a.k.a. add-ons) manager.
                    </li>
                    <li>
                        Click the blue toggle for an extension to disable it, or
                        click the ellipsis (3-dot) icon to reveal a menu to
                        Remove it.
                    </li>
                </ol>
                <p>
                    Our extensions cannot work after they have been disabled or
                    removed. If you are still experiencing undesired behavior in
                    Firefox, try following Mozilla’s instructions to
                    <a
                        href="//support.mozilla.org/en-US/kb/refresh-firefox-reset-add-ons-and-settings"
                        target="_blank"
                        >Refresh Firefox – reset add-ons and settings.</a
                    >
                </p>

                <h3 id="opera">Opera</h3>
                <ol>
                    <li>
                        In Opera’s address bar (a.k.a. omnibox), enter
                        <strong>opera://extensions</strong> and then press the
                        Enter (PC) or Return (Mac) key to open Opera’s
                        Extensions manager.
                    </li>
                    <li>
                        Click the Disable button for an extension to turn off
                        the extension without removing it, or click its Remove
                        icon (X) to uninstall it.
                    </li>
                </ol>
                <p>
                    Our extensions cannot work after they have been disabled or
                    removed. If you are still experiencing undesired behavior in
                    Opera, try following Opera’s instructions for
                    <a
                        href="//help.opera.com/en/latest/crashes-and-issues/#recoverBrowser"
                        target="_blank"
                        >How to recover your Opera browser.</a
                    >
                </p>

                <h3 id="edge">Microsoft Edge (Chromium based)</h3>
                <ol>
                    <li>
                        In Edge’s address bar, enter
                        <strong> edge://extensions</strong> and then press the
                        Enter (PC) or Return (Mac) key to open Edge’s Extensions
                        manager.
                    </li>
                    <li>
                        Click Remove for an extension to uninstall it, or toggle
                        its switch to turn off the extension without removing
                        it.
                    </li>
                </ol>
                <p>
                    Our extensions cannot work after they have been turned off
                    or removed. If you are still experiencing undesired behavior
                    in Edge, try Microsoft’s
                    <a
                        href="//support.microsoft.com/en-us/help/4533311/microsoft-edge-troubleshooting-tips-for-installing-and-updating"
                        target="_blank"
                        >Troubleshooting tips for installing and updating
                        Microsoft Edge</a
                    >
                    to reinstall it. We do not make extensions for Microsoft
                    Edge (Legacy) or Internet Explorer (IE).
                </p>

                <h3 id="other">Other Desktop Browsers</h3>
                <p>
                    <a href="/contact-us/" target="_blank">Email us</a> if your
                    browser isn’t listed above or you still need help. We are
                    only able to help with our extensions and the browsers
                    listed above. Google Chrome, Opera, and the new version of
                    Microsoft Edge introduced in 2020 are
                    <a href="//www.chromium.org/Home" target="_blank"
                        >Chromium-based</a
                    >
                    browsers of which there are many other variants. The
                    principle of entering <strong>about://extensions</strong> in
                    a Chromium-based browser’s address bar generally works to
                    open its extensions manager.
                </p>
            </div>
            <div class="footer-page-footer">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script>
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer.vue";

export default {
    components: {
        Header,
        Footer,
    },
};
</script>

<style scoped src="./footerPage.css"></style>
