<template>
    <div class="email-verify-popup-root">
        <transition name="fade" mode="out-in">
            <div
                v-if="show"
                class="email-verify-popup-wrapper p-shadow-15 p-grid"
            >
                <div class="email-verify-popup-icon-wrapper p-col-1">
                    <i class="email-verify-popup-icon pi pi-info-circle"></i>
                </div>
                <div class="email-verify-popup-center p-col-10">
                    <div class="evp-title">Email Verification Needed</div>
                    <div class="evp-text">
                        You must verify your email before you can use account
                        features.
                        <br />
                        Don't see the link?
                        <span class="evp-cta" @click="sendVerification()"
                            >Send verification email again!</span
                        >
                    </div>
                </div>
                <div class="email-verify-popup-close-wrapper p-col-1">
                    <div class="evp-close">
                        <i
                            class="evp-close-icon pi pi-times"
                            @click="show = false"
                        ></i>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import emailVerify from "./emailVerify";
import userMixin from "../../../store/mixins/user";
import {
    checkCode,
    applyCode,
    FIREBASE_AUTH_ERRORS,
} from "../../../auth/firebase";

export default {
    name: "email-verify-popup",
    mixins: [emailVerify, userMixin],
    components: {},
    data() {
        return {
            show: false,
            sending: false,
            hasVerified: false,
            runningInit: false,
        };
    },
    mounted() {
        if (!this.runningInit) this.init();
    },
    watch: {
        activeUser() {
            if (!this.activeUser) this.show = false;
            if (!this.runningInit) this.init();
        },
    },
    methods: {
        ...mapActions(["reloadUser"]),
        async init() {
            this.runningInit = true;
            if (this.activeUser && this.activeUser.emailVerified) {
                this.runningInit = false;
                return;
            }

            // Check for mode=email-verified and oobCode in url
            const params = new URLSearchParams(window.location.search);
            const mode = params ? params.get("mode") : null;
            const code = params ? params.get("oobCode") : null;

            const showVerify = true;

            if (mode && mode == "verifyEmail" && code && !this.hasVerified) {
                try {
                    const codeDetails = await checkCode(code);
                    if (
                        codeDetails &&
                        codeDetails.success &&
                        codeDetails.data &&
                        codeDetails.data.operation == "VERIFY_EMAIL"
                    ) {
                        try {
                            await applyCode(code);
                            await this.reloadUser();
                            this.$toast.add({
                                severity: "success",
                                group: "top-center",
                                summary: "Email Verified",
                                detail:
                                    "You have successfully verified your email.",
                                life: 5000,
                            });
                            this.show = false;
                            this.hasVerified = true;
                            this.runningInit = false;
                            return;
                        } catch (e) {
                            console.error(
                                "Error with applying email verification code"
                            );
                        }
                    } else if (
                        codeDetails &&
                        ((!codeDetails.success &&
                            codeDetails.data &&
                            codeDetails.data.code) ||
                            (codeDetails.success &&
                                codeDetails.data &&
                                codeDetails.data.operation != "VERIFY_EMAIL"))
                    ) {
                        switch (codeDetails.data.code) {
                            case FIREBASE_AUTH_ERRORS.invalidActionCode:
                            case FIREBASE_AUTH_ERRORS.expiredActionCode:
                                this.$toast.add({
                                    severity: "error",
                                    group: "top-center",
                                    summary: "Invalid Email Verification code",
                                    detail: "Link is either expired or invalid",
                                    life: 5000,
                                });
                                break;
                            case FIREBASE_AUTH_ERRORS.userNotFound:
                            case FIREBASE_AUTH_ERRORS.userDisabled:
                                this.$toast.add({
                                    severity: "error",
                                    group: "top-center",
                                    summary: "Invalid Account",
                                    detail:
                                        "The account associated with this link is disabled or deleted",
                                    life: 5000,
                                });
                                break;
                        }
                    }
                } catch (e) {
                    console.error("Error with email verification flow: ", e);
                }

                if (
                    showVerify &&
                    this.activeUser &&
                    !this.activeUser.emailVerified
                ) {
                    setTimeout(() => {
                        this.show = true;
                    }, 5000);
                }
            } else if (this.activeUser && !this.activeUser.emailVerified) {
                this.show = true;
            }
            this.runningInit = false;
        },
    },
};
</script>

<style scoped src="./emailVerifyPopup.css"></style>
