<template>
    <Dialog
        id="cookbook-add-dialog"
        :modal="true"
        :dismissableMask="true"
        :showHeader="false"
        contentClass="cookbook-add-dialog-content-wrapper"
        :visible="showAddDialog"
        @update:visible="hideAddDialog"
    >
        <div class="recipe-add-root">
            <div class="recipe-add-wrapper">
                <div class="page-title">Add New Recipe</div>
                <p class="page-sub">
                    Have a recipe thats not on Freshy? Add the website address
                    below to add the recipe to Freshy Recipe Book.
                </p>
                <div class="p-field">
                    <label for="addRecipe">Enter Recipe URL</label>

                    <div class="p-formgroup-inline">
                        <div class="p-field">
                            <InputText
                                type="text"
                                v-model="queryUrl"
                                id="addRecipe"
                                class="ra-input"
                                placeholder="e.g. https://cooking.com/recipe"
                                @keypress.enter="onSubmit()"
                            />
                        </div>
                        <div class="ra-input-submit-wrapper">
                            <Button
                                @click="onSubmit()"
                                type="button"
                                label="Add Recipe"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script>
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { getRecipesFromApi } from "../../fetchFunctions";
import recipeMixin from "../componentMixins/recipeMixin";

export default {
    mixins: [recipeMixin],
    components: {
        InputText,
        Button,
        Dialog,
    },
    data() {
        return {
            queryUrl: null,
            loading: false,
            showAddDialog: false,
        };
    },
    methods: {
        show() {
            this.showAddDialog = true;
        },
        hideAddDialog(value) {
            if (!value) this.showAddDialog = false;
        },
        async onSubmit() {
            if (this.queryUrl && !this.loading) {
                const url = this.queryUrl;
                this.loading = true;
                const recipe = await getRecipesFromApi(url, "url");
                this.loading = false;
                if (recipe) {
                    if (
                        recipe.status &&
                        recipe.results[url] &&
                        recipe.results[url].data
                    ) {
                        // Recipe exists, save it
                        const { data } = recipe.results[url];
                        data.recipedata = recipe.results[url].data;
                        data.uuid = recipe.results[url].uuid;
                        data.url = recipe.results[url].data.canonical;
                        try {
                            await this.onSaveRecipeClick(data);
                        } catch (e) {
                            void e;
                        }
                    } else {
                        this.$toast.add({
                            severity: "error",
                            summary: "Recipe not saved",
                            detail:
                                "Sorry, we are unable to extract this recipe to add to your Recipe Book.",
                            life: 3000,
                        });
                    }
                }
                this.showAddDialog = false;
            }
        },
    },
};
</script>

<style scoped src="./recipeAdd.css"></style>
